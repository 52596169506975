import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';


const ProjectTypeForm = (props) => {

  const [projectTypeObj, setProjectTypeObj] = useState({})
  
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(props.schema)
  });

  useEffect(() => {
    setProjectTypeObj(props?.row_info)
    console.log(projectTypeObj)
  }, [props?.row_info])

  return(     
    <form className="create_form" onSubmit={ handleSubmit(props.submit_function) }>
      <div className="modal-body">
        <div className="form-group row mt-3">
          <div className="col-4">
            <label className="col-12 form-label">Nome</label>
            <input type="text" id="name" className="form-control" value={projectTypeObj?.name} onChange={(e) => setProjectTypeObj(projectTypeObj => ({...projectTypeObj , name: e.target.value}) )} {... register("name")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.first_name?.message}</label>
          </div>
          <div className="col-4">
            <label className="col-12 form-label">Descrição</label>
            <input type="text" id="description"className="form-control" value={projectTypeObj?.description} {... register("description")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors.last_name?.message}</label>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-primary">Submeter</button>
      </div>
    </form>
  )
}

export default ProjectTypeForm;