import { useState } from 'react';
import * as yup from 'yup';
import $ from 'jquery';

import { BsFillPlusCircleFill } from 'react-icons/bs';
import { FaPen, FaTrash } from 'react-icons/fa';
import { BiChat } from 'react-icons/bi';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

import { Navbar } from '../components/navbar';
import { Footer } from '../components/footer';
import { ModalCreate } from '../components/modal_create';
import { ModalEdit } from '../components/modal_edit';
import Table from '../components/table';

import { useDelete } from '../hooks/useDelete';
import { useGetDataById } from '../hooks/useGetDataById';


export const Opportunities = () => {

	const [deletionError, setDeletionError] = useState(null);

	const [rowObj, setRowObj] = useState({})
	const [rowObjOpportunity, setRowObjOpportunity] = useState({})

	const { mutate: delete_ } = useDelete(setDeletionError);
	const { mutate: getDataById_ } = useGetDataById();
	
  const opportunities_table_columns = [
		{
			Header: "Id",           
			accessor: "id_opportunity"     
		},
		{
			Header: "Nome", 
			accessor: "name"
		},
		{
			Header: "Área de Negócio", 
			accessor: "business_area.name"
		},
		{
			Header: "Tipo de Projeto", 
			accessor: "project_type.name"
		},
		{
			Header: "Tipo de Oportunidade", 
			accessor: "opportunity_type.name"
		},
		{
			Header: "Requisitos", 
			accessor: "requirement.description"
		},
		{
			Header: "Estado", 
			accessor: "state.name"
		},
		{
			Header: "Opções",
			Cell: ({row}) => (
				<>
					<BiChat 
						data-bs-toggle="modal" 
						data-bs-target="#create" 
						onClick={ () => { setRowObjOpportunity(row.values); console.log(row.values.id_opportunity); }}
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginRight": "10px", "cursor": "pointer", "color": "#36659d" }} 
					/>
					<FaPen 
						data-bs-toggle="modal" 
						data-bs-target="#edit" 
						onClick={ () => setRowObj(row.values) }
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginRight": "10px", "cursor": "pointer", "color": "#36659d" }} 
					/>
					<FaTrash 
						className="lixo_" 
						onClick={ () => deleteData('states', row.values.id_opportunity) } 
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginLeft": "10px", "cursor": "pointer", "color": "#36659d" }} 
					/>
				</>
			),  
		}
	];

	const schema = yup.object().shape({
    name: yup.string().required("Preencher!")
  });

	const schema_contact = yup.object().shape({
    email   			: yup.string().email().required("Preencher!"),
    phone_number 	: yup.number("Preencher contacto!").typeError("Preencher contacto!").required("Preencher!"),
    contact_date 	: yup.date("Preencher data!").typeError("Preencher data!").required("Preencher!"),
  });

	function getDataByID(table_name, id){
		const data_obj = {table_name: table_name, id: id, modal_title: "Oportunidades"};
		getDataById_(data_obj);
	}

	function deleteData(table_name, id){

		const data_obj = {table_name: table_name, id: id};

		Swal.fire({
			title: 'Tem a certeza?',
			text: 'Não poderá recuperar este ficheiro!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Confirmar'
		}).then((result) => {
				if(result){
					delete_(data_obj);
				}else if(result.dismiss === Swal.DismissReason.cancel){
					Swal.fire(
						'Cancelado',
						'Operação cancelada!',
						'error'
					)
				}
		}) 
	};

  return( 
		<div className="container-xxl bg-white p-0">
			<Navbar/>
			<div className='row'>
				<div className='col-8 offset-2 mt-5'>
					<h1 style={{'textAlign' : 'center'}}>
            Oportunidades
						<i style={{ paddingLeft: '15px', color: '#36659d' }}>
							<BsFillPlusCircleFill 
              value={{ "height": "2em", "width": "2em" }} 
              style={{ cursor: 'pointer' }} 
              data-bs-toggle="modal" 
              data-bs-target="#create"/>
						</i>
					</h1>					
					<Table table_name="opportunities" table_columns={opportunities_table_columns}/>
				</div>
			</div>
			<Footer/>
			{/* <ModalCreate table_name="opportunities" modal_title="Oportunidades" schema={schema}/> */}
			<ModalCreate 	
				table_name="contacts" 
				modal_title="Contacto" 
				schema={schema_contact} 
				current_opportunity_={rowObjOpportunity}
			/>
			<ModalEdit table_name="opportunities" modal_title="Oportunidades" schema={schema} row_data={rowObj}/>
		</div>
  );
};