import $ from 'jquery';
import axios from 'axios';
import * as yup from 'yup';
import { UserContext } from "../App";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useContext } from "react";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import '../template/css/login.css';

import { login } from '../auth';

export const Login = (props) => {

	console.log(props.isLoggedIn);

	const [newUsername, setNewUsername] = useState("");
	const [newPass, setNewPass] = useState("");
	const dispatch = useDispatch();
	let navigate = useNavigate(); 

	//isto vai buscar o store => reducer.user que se está a exportar no store.js
	const username__ = useSelector((state) => state.user.value.username);	
		
	const schema_create_user = yup.object().shape({
    first_name  		: yup.string("Preencher nome!").required("Preencher nome!"),
    last_name   		: yup.string("Preencher apelido!").required("Preencher apelido!"),
    email       		: yup.string("Preencher email!").email("Preencher email!").required("Preencher email!"),
    contact     		: yup.number("Preencher contacto!")
												.positive("Número positivo!")
												.integer("Preencher contacto!")
												.min(9, 'Tem que ter 9 numeros!')
												.typeError("Preencher contacto!")
												.required("Preencher!"),
    birth_date  		: yup.date("Preencher data de nascimento!").typeError("Preencher data de nascimento!"),     //  ver min(),
    city						: yup.string("Preencher localidade!").required("Preencher localidade!"),
		pwhash					: yup.string("Preencher password!")
												.password("Preencher password!")
												.min(8, "Tem que ter no mínimo 8 caracteres!")
												.minUppercase(1, "Tem que ter no mínimo 1 caracter maiúsculo!")
												.minLowercase(1, "Tem que ter no mínimo 1 caracter minúsculo!")
												.minSymbols(1, "Tem que ter no mínimo 1 caracter especial!")
												.minNumbers(1, 'Tem que ter no mínimo 1 número!')
												.typeError("Preencher password!")
												.required("Preencher password!"),
		is_active				: yup.boolean(),
		//join_date				: yup.date(),
		password_changed: yup.boolean(),
		id_role					: yup.number(),
		confirm_pwhash	: yup.string().oneOf([yup.ref('pwhash'), null], "Palavras-Passe não são iguais!"),
  });

	const { register, unregister, handleSubmit, formState: { errors }, getValues, setValue } = useForm({
    resolver	: yupResolver(schema_create_user)
  });
	
	useEffect(() => {
		setTimeout(() => {
			document.getElementById('container').classList.add('login_sign-in')
			document.querySelector('html').style.height = '100vh';
			//document.querySelector('body').style.overflow = 'hidden';
		}, 200)
	}, []); 

	var toggle__ = () => {
		document.getElementById('container').classList.toggle('login_sign-in');
		document.getElementById('container').classList.toggle('login_sign-up');
	}

	var forgotPass = () => {
		document.getElementById('login').classList.toggle('login_translate__');
		document.getElementById('forgot_password').classList.toggle('login_translate__');
	}

  const login_ = () =>{ 

		const user_info_obj = {email: newUsername, password: newPass}

		console.log(user_info_obj);

		Swal.fire({
			title: 'A efetuar login, por favor aguarde',
			allowOutsideClick: false,
			allowEscapeKey: false
		})
		Swal.showLoading();

		axios.post("https://pint-22-23-backend.onrender.com/api/login", user_info_obj)
					.then((res) => {
						if(res.data.success){
							Swal.close();
							Swal.fire({
								title: 'Login efetuado com sucesso!',
								icon: 'success',
								allowOutsideClick: false,
								allowEscapeKey: false
							})
							console.log(res.data)
							props.changeIsLoggedIn(true)
							dispatch(login({ email: newUsername, password: newPass, role: res.data.data.role.name }))
							document.cookie = 'loggedIn=true';
							document.cookie = 'username='+newUsername;
							document.cookie = 'role='+res.data.data.role.name;
							document.cookie = 'user_id='+res.data.data.id_user;
							let path = `/`; 
							navigate(path);
						} else {
							Swal.close();
							Swal.fire({
								title: 'Erro ao efetuar login!',
								text: res.data.message,
								icon: 'error',
								allowOutsideClick: false,
								allowEscapeKey: false
							})
						}
					})
					.catch((error) => {
						console.log(error)
						Swal.close();
						Swal.fire({
							title: 'Erro ao efetuar login!',
							icon: 'error',
							allowOutsideClick: false,
							allowEscapeKey: false
						})
					})
  }

	const send_email = () => {
		axios.post("https://pint-22-23-backend.onrender.com/api/recover_password/" + $('#recover_pass_email').val())
		.then((res) => {
			return Swal.fire({
				title: 'Email enviado',
				text: res.data.message,
				icon: 'success'
			})
		})
		.catch((error) => {
			return Swal.fire({
				title: 'Erro',
				text: error.data.message,
				icon: 'success'
			})
		})
	} 

	const register_user = () => {
		unregister("confirm_pwhash")
		setValue('password_changed', true)
		setValue('id_role', 4)

		const data__ = getValues()

		console.log(data__)
		console.log(123)
		
		Swal.showLoading();

		axios.post("https://pint-22-23-backend.onrender.com/api/users/create", data__)
					.then((res) => {
						Swal.close();
						Swal.fire({
							title: 'Registo efetuado com sucesso!',
							text: 'Aceda ao seu email para ativar a conta',
							icon: 'success',
							allowOutsideClick: false,
							allowEscapeKey: false
						})


						/* if(res.data.success === true){
							Swal.close();
							Swal.fire({
								title: 'Registo efetuado com sucesso!',
								text: 'Aceda ao seu email para ativar a conta',
								icon: 'success',
								allowOutsideClick: false,
								allowEscapeKey: false
							})

							console.log(res.data)
						} else {
							Swal.close();
							Swal.fire({
								title: 'Erro ao registar utilizador!',
								icon: 'error',
								allowOutsideClick: false,
								allowEscapeKey: false
							})
							//Swal.close();
						} */
					})
					.catch((error) => {
						console.log(error)
					})
	}


	return(
		<div id="container" className="login_container">
			<div className="login_row">
				<div id="register" className="login_col login_align-items-center login_flex-col login_sign-up">
					<div className="login_form-wrapper login_align-items-center">

						<form onSubmit={ handleSubmit(register_user) }>
							<div className="login_form login_sign-up">
								<div className="input-group" style={{'height':'59.2px'}}>
									<input type="text" placeholder="Nome" className="form-control" {... register("first_name")}/>
									<input type="text" placeholder="Apelido" className="form-control" {... register("last_name")}/>
								</div>
            		<label className="form-label" style={ {'color': 'red'}}>{errors.first_name?.message} {errors.last_name?.message}</label>
								
								<div className="login_input-group">
									<input type="email" placeholder="Email" {... register("email")}/>
            			<label className="form-label" style={ {'color': 'red'}}>{errors.email?.message}</label>
								</div>
								
								<div className="input-group" style={{'height':'59.2px'}}>
									<input type="number" placeholder="Contacto" className="form-control" {... register("contact")}/>
									<input type="date" min="1965-01-01" max="2006-12-31" className="form-control" {... register("birth_date")}/>
								</div>
            		<label className="form-label" style={ {'color': 'red'}}>{errors.contact?.message} {errors.birth_date?.message}</label>
								
								<div className="login_input-group">
									<input type="text" placeholder="Localidade" {... register("city")}/>
            			<label className="form-label" style={ {'color': 'red'}}>{errors.city?.message}</label>
								</div>								
								
								<div className="input-group" style={{'height':'59.2px'}}>
									<input type="password" placeholder="Password" className="form-control" {... register("pwhash")}/>
									<input type="password" placeholder="Confirmar password" className="form-control" {... register("confirm_pwhash")}/>
								</div>
								<label className="form-label" style={ {'color': 'red'}}>{errors.pwhash?.message} {errors.confirm_pwhash?.message}</label>

								<button className='mt-3' type="submit">Registar</button>
								<p>
									<span>Já tens conta?</span>
									<b onClick={ toggle__ } className="pointer">Entrar</b>
								</p>
							</div>
						</form>

					</div>					
				</div>

				<div id="login" className="login_col login_align-items-center login_flex-col login_sign-in">
					<div className="login_form-wrapper login_align-items-center">
						<div className="login_form login_sign-in">
							<div className="login_input-group">
								<i className='bx bxs-user'></i>
								{username__}
								<input id="username" type="text" placeholder="Email" onChange={ (e) => setNewUsername(e.target.value.trim()) }/>
								{/* <small>Visitante - carlos@email.com</small><br/>
								<small>Colaborador - rubencampos1998@hotmail.com</small><br/>
								<small>Admin - jorge@email.com</small><br/>
								<small>Gestor Conteudos - isabel@email.com</small><br/>
								<small>Gestor Ideias - margarida@hotmail.com</small><br/> */}
							</div>
							<div className="login_input-group">
								<i className='bx bxs-lock-alt'></i>
								<input type="password" placeholder="Palavra-chave" onChange={ (e) => setNewPass(e.target.value.trim()) }/>
								{/* <small>Visitante - Carlos_123</small><br/>
								<small>Colaborador - Armando_123</small><br/>
								<small>Admin - Jorge_123</small><br/>
								<small>Gestor Conteudos - Isabel_123</small><br/>
								<small>Gestor Ideias - Margarida_123</small><br/> */}
							</div>
							<button onClick={() => { login_() } }>Entrar</button>
							<p className="pointer" onClick={forgotPass}><b>Recuperar palavra-passe</b></p>
							<p>
								<span>Não tens conta?</span>
								<b onClick={toggle__} className="pointer"> Criar conta</b>
							</p>
						</div>
					</div>
					<div className="login_form-wrapper">			
					</div>
				</div>

				<div id="forgot_password" className="login_col login_align-items-center login_flex-col login_sign-in login_translate__ " 
					style={{ transition: '1s', position: 'absolute', top: '198px', zIndex: '1', left: '765px' }}>
					<div className="login_form-wrapper login_align-items-center">
						<div className="login_form login_sign-in">
							<div className="login_input-group">
								<i className='bx bxs-user'></i>
								<input id="recover_pass_email" type="text" placeholder="Email"/>
							</div>
							<button onClick={ () => { send_email() } }>Enviar email</button>
							<br/>
							<br/>
							<br/>
							<p>
								<span>Já tens conta?</span>
								<b onClick={ forgotPass } className="pointer"> Fazer Login</b>
							</p>
						</div>
					</div>
					<div className="login_form-wrapper">			
					</div>
				</div>
			</div>

			<div className="login_row login_content-row">
				<div className="login_col login_align-items-center login_flex-col">
					<div className="login_img login_sign-in">	
						<img 	alt="" width={'450px'} style={{'background': 'white', 'borderRadius': '10px'}}
									src="https://www.neptune-software.com/wp-content/uploads/2017/11/logotipo_softinsa_2016.png" />	
					</div>
					<div className="login_text login_sign-in">
						<h2 style={{'fontSize':'45px'}}>Business Solutions</h2>		
					</div>
				</div>
				<div className="login_col login_align-items-center login_flex-col">
					<div className="login_img login_sign-up">	
						<div className="login_text login_sign-up">
							<h2 style={{'fontSize':'45px'}}>Junta-te a nós!</h2>		
						</div>
						{/* <img 	alt="" width={'450px'} style={{'background': 'white', 'borderRadius': '10px'}}
									src="https://www.neptune-software.com/wp-content/uploads/2017/11/logotipo_softinsa_2016.png" /> */}					
					</div>
				</div>
			</div>
		</div>
	);
	
};