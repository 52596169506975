import { useEffect, useState } from "react";
import axios from 'axios';
import * as yup from 'yup';
import $ from 'jquery';

import { useGetData } from '../hooks/useGetData';
import { ModalCreate } from '../components/modal_create';
import { JobsIcons } from '../components/jobs_icons';


export const JobOffersTabs = (props) => {

  const [allJobs, setAllJobs] = useState([]);
  const [allJobsRemote, setAllJobsRemote] = useState([]);
  const [allJobsHybrid, setAllJobsHybrid] = useState([]);
  const [allJobsPresencial, setAllJobsPresencial] = useState([]);
  const [jobId, setJobId] = useState(0);
  const [userId, setUserId] = useState(0);
  
  const [remote, setRemote] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    fetchData_jobs();

    console.log(fetching)
    return () => {
      setFetching(true);     
      console.log(fetching)  
    };
  }, [])

  async function fetchData_jobs(){
    setAllJobsRemote([])
    setAllJobsHybrid([])
    setAllJobsPresencial([])
    await axios.get("https://pint-22-23-backend.onrender.com/api/job_offers")
                .then(async (res) => {
                  console.log(res.data.data);
                  setAllJobs(res.data.data);
                  for( let i =  0; i < res.data.data.length; i++ ){
                    switch(res.data.data[i].work.name + ':' + res.data.data[i].state.name){
                      case 'Remoto:Ativo':
                        setAllJobsRemote(remote => [...remote, res.data.data[i]])
                        break;
                      case 'Híbrido:Ativo':
                        setAllJobsHybrid(hibrido => [...hibrido, res.data.data[i]])
                        break;
                      case 'Presencial:Ativo':
                        setAllJobsPresencial(presencial => [...presencial, res.data.data[i]])
                        break;
                      default:
                        break;
                    }
                  }               
                })
  }

	const schema = yup.object().shape({
    name              : yup.string().required("Preencher!"),
    email             : yup.string().email("Inserir email!").required("Preencher!"),
    contact           : yup.number("Preencher!").positive("Preencher!").integer("Preencher!").min(9).required("Preencher!"),   
    resume            : yup.string(),        
    salary_expectation: yup.number("Preencher!").positive("Preencher!").integer("Preencher!").required("Preencher!"),        
    description       : yup.string(),  
    cv                : yup.string(),           
		id_user			      : yup.number().required(),
    id_joboffer       : yup.number(),
    id_opportunity    : yup.number()
  });

  setTimeout(() => {
    $('.application_btn').on('click', function(e){
      e.stopPropagation();
      e.preventDefault();
      setJobId( $(this).attr('data-job-id') );
      setUserId( props.getCookieFn_('user_id') );

      console.log( $(this).attr('data-job-id') )
      console.log(props.getCookieFn_('user_id'))
      //$('#create').modal('show');
    })
  }, 1000)

  const remote_jsx = (array) => {    
    if( array.length > 0 ){
      return array?.map((object, i) => {   
        return( <div className="job-item p-4 mb-4" key={i}> 
                  <div className="row g-4">
                    <div className="col-sm-12 col-md-8 d-flex align-items-center">
                      <JobsIcons first_letter={object?.title.substring(0, 1)}/>
                      <div className="text-start ps-4">
                        <h5 className="mb-3">{object.title}</h5>
                        <span className="text-truncate me-3">
                          <i className="fa fa-map-marker-alt text-primary me-2"></i>
                          {object.center?.name} - {object.center?.address}
                        </span>
                        <span className="text-truncate me-3">
                          <i className="far fa-clock text-primary me-2"></i>
                          {object.job_offer_type?.name}
                        </span>
                        <span className="text-truncate me-3">
                          <i className="far fa-clock text-primary me-2"></i>
                          {object.work?.name}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                      <div className="d-flex mb-3">
                        <button 
                          className="btn btn-primary application_btn" 
                          style={{'backgroundColor':'#36659d', 'border':'1px solid #36659d'}}
                          data-bs-toggle="modal" 
                          data-bs-target="#create" 
                          data-job-id={object?.id_joboffer}
                        >
                          Candidatar-me
                        </button>
                      </div>
                      <small className="text-truncate">
                        <i className="far fa-calendar-alt text-primary me-2"></i>
                        Data limite: {object?.deadline}
                      </small>
                    </div>
                  </div>
                </div> 
        )
      })
    } else {
      return (<div className="job-item p-4 mb-4">
                <div className="row g-4">
                  <h3>Sem vagas para trabalho remoto!</h3>                    
                </div>
              </div>)
    }
  }

  const hybrid_jsx = (array) => {
    if( array.length > 0 ){
      return array?.map((object, i) => {   
        return( <div className="job-item p-4 mb-4" key={i}>
                  <div className="row g-4">
                    <div className="col-sm-12 col-md-8 d-flex align-items-center">
                      <JobsIcons first_letter={object?.title.substring(0, 1)}/>
                      <div className="text-start ps-4">
                        <h5 className="mb-3">{object.title}</h5>
                        <span className="text-truncate me-3">
                          <i className="fa fa-map-marker-alt text-primary me-2"></i>
                          {object.center?.name} - {object.center?.address}
                        </span>
                        <span className="text-truncate me-3">
                          <i className="far fa-clock text-primary me-2"></i>
                          {object.job_offer_type?.name}
                        </span>
                        <span className="text-truncate me-3">
                          <i className="far fa-clock text-primary me-2"></i>
                          {object.work?.name}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                      <div className="d-flex mb-3">
                        <button 
                          className="btn btn-primary application_btn" 
                          style={{'backgroundColor':'#36659d', 'border':'1px solid #36659d'}}
                          data-bs-toggle="modal" 
                          data-bs-target="#create" 
                          data-job-id={object?.id_joboffer}
                        >
                          Candidatar-me
                        </button>
                      </div>
                      <small className="text-truncate">
                        <i className="far fa-calendar-alt text-primary me-2"></i>
                        Data limite: {object?.deadline}
                      </small>
                    </div>
                  </div>
                </div> 
        )
      })
    } else {
      return (<div className="job-item p-4 mb-4">
                <div className="row g-4">
                  <h3>Sem vagas para trabalho hibrido!</h3>                    
                </div>
              </div> )
    }
  }

  const presencial_jsx = (array) => {
    if( array.length > 0 ){
      return array?.map((object, i) => {
        return( <div className="job-item p-4 mb-4" key={i}>
                  <div className="row g-4">
                    <div className="col-sm-12 col-md-8 d-flex align-items-center">
                      <JobsIcons first_letter={object?.title.substring(0, 1)}/>
                      <div className="text-start ps-4">
                        <h5 className="mb-3">{object.title}</h5>
                        <span className="text-truncate me-3">
                          <i className="fa fa-map-marker-alt text-primary me-2"></i>
                          {object.center?.name} - {object.center?.address}
                        </span>
                        <span className="text-truncate me-3">
                          <i className="far fa-clock text-primary me-2"></i>
                          {object.job_offer_type?.name}
                        </span>
                        <span className="text-truncate me-3">
                          <i className="far fa-clock text-primary me-2"></i>
                          {object.work?.name}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                      <div className="d-flex mb-3">
                        <button 
                          className="btn btn-primary application_btn" 
                          style={{'backgroundColor':'#36659d', 'border':'1px solid #36659d'}}
                          data-bs-toggle="modal" 
                          data-bs-target="#create" 
                          data-job-id={object?.id_joboffer}
                        >
                          Candidatar-me
                        </button>
                      </div>
                      <small className="text-truncate">
                        <i className="far fa-calendar-alt text-primary me-2"></i>
                        Data limite: {object?.deadline}
                      </small>                    
                    </div>
                  </div>
                </div> 
        )
      })
    } else {
      return (  <div className="job-item p-4 mb-4">
                  <div className="row g-4">
                    <h3>Sem vagas para trabalho presencial!</h3>                    
                  </div>
                </div>)
    }
  }

  /* $('#tab-1 .job-item').eq(1).remove()
  $('#tab-2 .job-item').eq(1).remove()
  $('#tab-3 .job-item').eq(1).remove()

  if( $('.job-item').length > 0 ){
    $('.job-item').remove();
  } 
  { $('.job-item').length > 0 ? $('.job-item').remove() : '' }
  */
  return (    
    <>
      <div id="tab-1" className="tab-pane fade show p-0 active">       
        {/* {remote_jsx(allJobsRemote)} */}
        {remote_jsx(props.allJobsRemote)}
      </div>
      <div id="tab-2" className="tab-pane fade show p-0">
        {/* {hybrid_jsx(allJobsHybrid)} */}
        {hybrid_jsx(props.allJobsHybrid)}
      </div>
      <div id="tab-3" className="tab-pane fade show p-0">
        {/* {presencial_jsx(allJobsPresencial)} */}
        {presencial_jsx(props.allJobsPresencial)}
      </div>
			<ModalCreate table_name="applications" modal_title="Candidatura" schema={schema} current_opportunity_={0} current_user_={userId} current_job_offer_={jobId}/>
    </>
  )

};