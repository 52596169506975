import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import axios from 'axios';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement
)

export const TotalUsersByRole = () => {

  const [usersPerRole, setUsersPerRole] = useState([])


  useEffect(() => {
    const total_user_by_role = async () => {
      await axios.get("https://pint-22-23-backend.onrender.com/api/dashboard/total_user_by_role")
      .then((res) => {
        setUsersPerRole(res.data.data)
        console.log(usersPerRole)
      })
      .catch((error) => {
        console.log(error)
      })  
    }
    total_user_by_role()
  }, [])




  var data_ = {
    labels: usersPerRole.length > 1 ? usersPerRole?.map(x => x.role_name) : '',
    datasets: [{
      data: usersPerRole.length > 1 ? usersPerRole?.map(x => x.number_users_per_role) : '',
      borderWidth: 1
    }]
  }

  var options= {
    maintainAspectRatio: true,
    scales: {
      yAxes:[{
        ticks: {
            stepSize: 1,
            beginAtZero: true,
            display: true,
            precision: 0
        }
      }]
    }
  }

  return(
    <div className="card col-4 offset-4" style={{'border':'4px solid #36659d', 'borderRadius':'7px','color':'#36659d'}}>
      <div className="card-body mb-3 " style={{'height':'400px'}}>
        <h5 className="card-title">Utilizadores por cargo</h5>
        <Bar
          data={data_}
          height={400}
          options={options}/>          
      </div>
    </div>
  )

}