import { useState } from 'react';

import { BsFillPlusCircleFill } from 'react-icons/bs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import * as yup from 'yup';

import { Navbar } from '../components/navbar';
import { Footer } from '../components/footer';
import { ModalCreate } from '../components/modal_create';
import Table from '../components/table';


export const BusinessAreas = () => {

  const business_areas_table_columns = [
		{
			Header: "Id",           
			accessor: "id_businessarea"     
		},
		{
			Header: "Nome", 
			accessor: "name"
		}
	];

  const schema = yup.object().shape({
    name  				: yup.string().required("Preencher!"),
    description   : yup.string().required("Preencher!")
  });


  return( 
		<div className="container-xxl bg-white p-0">
			<Navbar/>
			<div className='row'>
				<div className='col-8 offset-2 mt-5'>
					<h1 style={{'textAlign' : 'center'}}>
						Áreas de Negócio
						<i style={{ paddingLeft: '15px', color: '#36659d' }}>
							<BsFillPlusCircleFill 
              value={{ "height": "2em", "width": "2em" }} 
              style={{ cursor: 'pointer' }} 
              data-bs-toggle="modal" 
              data-bs-target="#create"/>
						</i>
					</h1>					
					<Table table_name="business_areas" table_columns={business_areas_table_columns}/>
				</div>
			</div>
			<Footer/>
			<ModalCreate table_name="business_areas" modal_title="Áreas de Negócio" schema={schema}/>
		</div>
  );

}