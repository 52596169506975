import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';

import { useEdit } from '../../hooks/useEdit';
import { useGetData } from '../../hooks/useGetData';
import { useCreate } from '../../hooks/useCreate';


const RoleForm = (props) => {

  const { mutate: edit_ } = useEdit();
  const { mutate: create_ } = useCreate();
  
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    resolver: yupResolver(props.schema)
  });

  useEffect(() => {    
    setValue('name', props?.row_info?.name)
    setValue('description', props?.row_info?.description)
  }, [props?.row_info, setValue])

  const submit_ = () => {
		const data_obj = {table_name: props.table_name, data: getValues(), id: props?.row_info?.id_role};
    console.log(data_obj)
    props.utility === 'edit' ? edit_(data_obj) : create_(data_obj);
  }

  return(     
    <form className="create_form" onSubmit={ handleSubmit(submit_) }>
      <div className="modal-body">
        <div className="form-group row mt-3">
          <div className="col-4">
            <label className="col-12 form-label">Nome</label>
            <input type="text" id="name" className="form-control" {... register("name")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.name?.message}</label>
          </div>
          <div className="col-4">
            <label className="col-12 form-label">Descrição</label>
            <input type="text" id="description"className="form-control" {... register("description")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors.description?.message}</label>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-primary">Submeter</button>
      </div>
    </form>
  )
}

export default RoleForm;