import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export const useCreate = () => {

  return useMutation((data_obj) => {

    let table_name = data_obj.table_name;
    let data = data_obj.data;

    return axios.post("https://pint-22-23-backend.onrender.com/api/"+table_name+"/create", data)
                .then( (res) => {                                    
                    if(res.data.success === true){
                      $('.create_form')[0].reset();  
                      return Swal.fire({
                              title: res.data.message,
                              icon: 'success'
                            })
                    }else{    
                      return Swal.fire({
                              title: res.data.message,
                              icon: 'error'
                            })
                    }
                })
                .catch( (error) => {
                  return Swal.fire({
                          title: 'Erro!',
                          icon: 'error'
                        })
                })
  })  

}

  /*const { data: create_record, isLoading, haveError } = useMutation(["create_record"], () => {
    return axios.post("http://localhost:3008/api/"+table_name+"/create", data_to_insert)
                .then((res) => res)
  });

  return { create_record, isLoading, haveError }; */


/*export const useCreate = (table_name, data) => {
  return axios.post("http://localhost:3008/api/"+table_name+"/create", data)
              .then( 
                function(res){                  
                  $('.create_form')[0].reset();
                  return res;
              })
              .catch(
                function(error){
                  alert(error);
              })
};
*/

/* export const useCreate = ()  => {
  return useMutation(addData()); 
}; */