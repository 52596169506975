import { useEffect, useState } from "react";
import axios from 'axios';

import { JobOffersTabs } from "./job_offers_tabs";

// Create a parent component that fetches data and stores it in its state
export const JobOffersTabsData = (props) => {
  const [allJobsRemote, setAllJobsRemote] = useState([]);
  const [allJobsHybrid, setAllJobsHybrid] = useState([]);
  const [allJobsPresencial, setAllJobsPresencial] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get("https://pint-22-23-backend.onrender.com/api/job_offers");
      const data = response.data.data;
      
      const remoteJobs = data.filter(item => item.work.name === 'Remoto' && item.state.name === 'Ativo');
      const hybridJobs = data.filter(item => item.work.name === 'Híbrido' && item.state.name === 'Ativo');
      const presencialJobs = data.filter(item => item.work.name === 'Presencial' && item.state.name === 'Ativo');

      setAllJobsRemote(remoteJobs);
      setAllJobsHybrid(hybridJobs);
      setAllJobsPresencial(presencialJobs);
    }

    fetchData();
  }, []);

  return (
    <JobOffersTabs
      getCookieFn_={props.getCookieFn_}
      allJobsRemote={allJobsRemote}
      allJobsHybrid={allJobsHybrid}
      allJobsPresencial={allJobsPresencial}
    />
  );
}