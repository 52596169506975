import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export const useDelete = ( setDeletionError ) => {

  return useMutation((data_obj) => {

    let table_name = data_obj.table_name;
    let id = data_obj.id;
    
    console.log(data_obj)

    //https://pint--22-23---backend.fly.dev/api/users

    return axios.post("https://pint-22-23-backend.onrender.com/api/"+table_name+"/delete", {id: id})
                .then((res) => {   
                  console.log(res);
                  if(res.data.success){
                    Swal.fire({  
                      title: 'Apagado!',
                      text: res.data.message,
                      icon: 'success'
                    }) 
                  }else{                    
                    Swal.fire({  
                      title: 'Erro!',
                      text: res.data.message,
                      icon: 'warning'
                    })
                  }
                })
                .catch((error) => {
                  return Swal.fire({
                          title: 'Erro!',
                          icon: 'error'
                        })
                })
  })

  /* Swal.fire({
    title: 'Tem a certeza?',
    text: 'Não poderá recuperar este ficheiro!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Confirmar'
  }).then((result) => {
      if(result){
        deleteData(table_name, id);
      }else 
      if(result.dismiss === Swal.DismissReason.cancel){
        Swal.fire(
          'Cancelado',
          'Operação cancelada!',
          'error'
        )
      }
  }) */
  
/*   const deleteData = () => {

  }; */

    /* const { isLoading, haveError, error } = useQuery(() => {

      isLoading ? console.log('loading') : console.log('not loading');

      if(haveError){
        Swal.fire(  
          'Erro!',
          'Ocorreu um erro... ',
          'warning'
        )
      }

      return Axios.post("http://localhost:3008/api/"+table_name+"/delete", id)
              .then((res) => {   
                if(res.data.success){
                  Swal.fire(  
                    'Apagado!',
                    'O utilizador foi eliminado!',
                    'success'
                  ) 
                }
              })
              .catch((error) => {
                Swal.fire(  
                  'Erro!',
                  'Ocorreu um erro... ' + error,
                  'warning'
                )
              })
    })

    return { isLoading, haveError };  */
}
