export const Footer = () => {
  return(
    <div className="container-fluid bg-dark text-white-50 footer mt-5 wow fadeIn">
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-12 text-center mb-3 mb-md-0">
              <p>&copy; Softinsa Business Solutions | {new Date().getFullYear()}, All Right Reserved.</p>               
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};