import { useNavigate } from "react-router-dom";
import { AiOutlineCaretDown } from 'react-icons/ai';
import { useEffect, useState } from "react";

import { JobsIcons } from '../components/jobs_icons';


export const Navbar = (props) => {
	
	const [curentUserRole, setCurentUserRole] = useState("");

	let navigate = useNavigate(); 

	function getCookie(cookieName) {
		let cookie = {};
		document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
		})
		return cookie[cookieName];
	}

	useEffect( () => {
		console.log(props.user_loggedIn_role)
		setCurentUserRole(getCookie('role'))
		console.log(curentUserRole)
	}, [props.user_loggedIn_role, curentUserRole])

	return(
		<nav className=" col-12 navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
			<button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
				<span className="navbar-toggler-icon"></span>
			</button>
			<div className=" col-12 collapse navbar-collapse" id="navbarCollapse">
				<div className="col-3">
					<a className="navbar-brand d-flex align-items-center text-center py-0 px-4 px-lg-5">
					{/* <img alt="" src="https://www.neptune-software.com/wp-content/uploads/2017/11/logotipo_softinsa_2016.png" width={'250px'}/> */}
					<img alt="" src="https://res.cloudinary.com/ds52cnce2/image/upload/v1693694020/logo_softinsa_da5tps.png" width={'190px'}/>
					</a>
					{/* <h3 className="m-0" style={{'color':"#36659d", 'textAlign':'center'}} >Business Solutions</h3> */}
				</div>

				<div className="col-9 navbar-nav ms-auto p-4 p-lg-0">
					{/* <a onClick={() => { navigate('/') } } className="nav-item nav-link active" style={{ 'cursor': 'pointer' }}>Inicio</a> */}
					<div className="col-1 nav-item dropdown">
						<span onClick={() => { navigate('/') } } className="nav-link" style={{ 'cursor': 'pointer' }}>
							Início
						</span>
					</div>
					<div className="col-1 nav-item dropdown">
						<span className="nav-link" style={{ 'cursor': 'pointer' }}>
							Vagas
						</span>
						<div className="dropdown-menu rounded-0 m-0">
							<button onClick={() => { navigate('/vagas') } } className="dropdown-item">Detalhes</button>
							<button onClick={() => { navigate('/candidaturas') } } className="dropdown-item">Candidaturas</button>
						</div>
					</div>
					<div className="col-2 nav-item dropdown">
						<span className="nav-link" style={{ 'cursor': 'pointer' }}>
							Oportunidades
						</span>
						<div className="dropdown-menu rounded-0 m-0">
							<button onClick={() => { navigate('/oportunidades') } } className="dropdown-item">Detalhes</button>
							<button onClick={() => { navigate('/candidaturas_oportunidades') } } className="dropdown-item">Candidaturas</button>
							<button onClick={() => { navigate('/contactos') } } className="dropdown-item">Contactos</button>
						</div>
					</div>
					<div className="col-2 nav-item dropdown">
						<span onClick={() => { navigate('/entrevistas') } } className="nav-link" style={{ 'cursor': 'pointer' }}>
							Entrevistas
						</span>
					</div>
					<div className="col-2 nav-item dropdown">
						<span onClick={() => { navigate('/minhas_candidaturas') } } className="nav-link" style={{ 'cursor': 'pointer' }}>
							As minhas candidaturas
						</span>
					</div>
					<div className="col-1 nav-item dropdown">
						{ curentUserRole === 'Administrador' || curentUserRole === 'Gestor de conteúdos' || curentUserRole === 'Gestor de Ideias' ? 
						<span className="nav-link " data-bs-toggle="dropdown" style={{ 'cursor': 'pointer' }}>
							Gerir
						</span> : ''
						}
						<div className="dropdown-menu rounded-0 m-0">
							<button onClick={() => { navigate('/utilizadores') } } className="dropdown-item">Utilizadores</button>
							<button onClick={() => { navigate('/estados') } } className="dropdown-item">Estados</button>
							<button onClick={() => { navigate('/areasnegocio') } } className="dropdown-item">Áreas de Negócio</button>
							<button onClick={() => { navigate('/tiposprojeto') } } className="dropdown-item">Tipos de Projeto</button>
							<button onClick={() => { navigate('/cargos') } } className="dropdown-item">Cargos</button>
						</div>
					</div>
					<div className="nav-item dropdown">
						{ curentUserRole === 'Visitante' ? 
							<span className="nav-link " data-bs-toggle="dropdown" style={{ 'cursor': 'pointer' }}>
								Páginas
								<i style={{marginLeft: '5px'}}>
									<AiOutlineCaretDown/>
								</i>	
							</span> : ''
							}
					</div>
					
				{/* <button className="col-3 btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block" 
								onClick={() => { navigate('/utilizadores') } }
				>
					Criar vaga
					<i className="fa fa-arrow-right ms-3"></i>
				</button> */}
				</div>
			</div>
		</nav>
	);
};