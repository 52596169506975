import { useEffect, useState, useMemo } from "react";
import { useGetData } from '../hooks/useGetData';
import axios from 'axios';
import $ from 'jquery';
import * as yup from 'yup';

import { ModalCreate } from '../components/modal_create';

export const Opportunities = (props) => {

  const [applicationsFromOpportunity, setApplicationsFromOpportunity] = useState([]);
  const [userId, setUserId] = useState(0);
  const [opportunityId, setOpportunityId] = useState(0);

  useEffect(() => {    
    const applications_from_opp = () => {
      axios.get("https://pint-22-23-backend.onrender.com/api/opportunities")
            .then((res) => {
              if(res.data.success){
                setApplicationsFromOpportunity(res.data.data);
                console.log(res.data.data)
                console.log(applicationsFromOpportunity)
              }
            })
            .catch((error) => {
              console.log(error)
            })
    }
    applications_from_opp()
    setUserId( props.getCookieFn('user_id') );
  }, [])


  const schema_opp = yup.object().shape({
    name              : yup.string().required("Preencher!"),
    email             : yup.string().email("Inserir email!").required("Preencher!"),
    contact           : yup.number("Preencher!")
                          .typeError("Preencher contacto!")
                          .positive("Preencher!")
                          .integer("Preencher!")
                          .min(9)
                          .required("Preencher!"),   
    description       : yup.string(),  
		id_user			      : yup.number().required()
  });

  setTimeout(() => {
    $('.application_for_opp_btn').on('click', function(e){
      e.stopPropagation();
      //e.preventDefault();
      setOpportunityId( $(this).attr('data-opportunity-id') );
      console.log($(this).attr('data-opportunity-id'))
      //setUserId( props.getCookieFn_('user_id') );
      //$('#create').modal('show');
    })
  }, 1000)

  //#0d6efd
  return(
    <div className="container-xxl py-5">
      <div className="container">
        <h1 className="text-center mb-5" style={{'color':'#36659d'}}>Oportunidades</h1>
        <div className="row g-4">          
          {applicationsFromOpportunity?.map((application_from_opp, i) => {
            return (
              <>
                <div className={i % 2 === 0 ? "card col-4 offset-1" : "card col-4 offset-2" } style={{'border':'4px solid #36659d', 'borderRadius':'7px','color':'#36659d'}}>
                  <div className="card-body">
                    <h2>{application_from_opp?.name}</h2>
                    <h5 style={{'color':'black'}}>&bull;Área de Negócio: {application_from_opp?.business_area.name}</h5>
                    <h5 style={{'color':'black'}}>&bull;Tipo de Oportunidade: {application_from_opp?.opportunity_type.name}</h5>
                  </div>
                  <div className="card-footer">
                    <button className="btn btn-primary application_for_opp_btn" 
                            style={{'backgroundColor':'#36659d', 'border':'1px solid #36659d'}}
                            data-bs-toggle="modal" 
                            data-bs-target="#create" 
                            data-opportunity-id={application_from_opp?.id_opportunity}>
                      Candidatar-me
                    </button>
                  </div>
                </div>
              </>
            )               
          })}
        </div>
      </div>
			<ModalCreate 
        table_name="applications" 
        modal_title="Candidatura a Oportunidade" 
        schema={schema_opp} 
        current_user_={userId} 
        current_job_offer_={0}
        current_opportunity_={opportunityId}
      />
    </div>
  );
};