import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import { Navbar } from '../components/navbar';
import { Carousel } from '../components/carousel';
import { Search } from '../components/search';
import { Opportunities } from "../components/opportunities";
import { Jobs } from "../components/jobs";
import { LogoutBtn } from "../components/logout_btn";
import { Footer } from "../components/footer";
import { Spinner } from "../components/spinner";
import { Dashboard } from "../components/dashboard";

import { logout } from '../auth';

export const Home = (props) => {

	const [newUsername, setNewUsername] = useState("");
	const [currentUserRole, setCurrentUserRole] = useState("");

	//isto vai buscar o store => reducer.user que se está a exportar no store.js
	//	const username__ = useSelector((state) => state.user.value.email);	
	const role__ = useSelector((state) => state.user.value.role);	

/* 	useEffect(() => {
		console.log('mounted')
    setCurrentUserRole(role__)
		console.log(currentUserRole)
  }, [role__, currentUserRole]) */

	const dispatch = useDispatch();

/* 	setTimeout(
		function(){
			return(
				<div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
					<div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}} role="status"></div>
				</div>
			);
		}, 5000);

		*/

	function getCookie(cookieName) {
		let cookie = {};
		document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
		})
		return cookie[cookieName];
	}

	return(
		<div className="container-xxl bg-white p-0">	 {/* style={{ 'margin': '3% 6.5%' }} */}
			<Navbar user_loggedIn_role={role__}/>
			<div className="col-12" style={{alignItems: 'center'}}>
				<p style={{ margin: '0% 0', fontSize: '25px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}> 
					<div className="mt-3 badge text-wrap" style={{'backgroundColor':'#36659d'}}>
						Seja bem vindo { !getCookie('username') ? "no one": getCookie('username') + ' - ' + getCookie('role') }
					</div>					 
				</p>
			</div>
			{ getCookie('role') === 'Administrador' || getCookie('role').indexOf('Gestor') !== -1 ? <Dashboard/> : ''}
			{/* <Search/> */}
			<Opportunities getCookieFn={getCookie}/>
			<Jobs getCookieFn={getCookie}/>
			<LogoutBtn dispatch_={dispatch} changeLoggedIn={props.changeIsLoggedIn} />
			<Footer/>			
		</div>
	);
};
