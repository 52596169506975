import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';

import { GrDocumentText } from 'react-icons/gr';

const MeetingForm = (props) => {
  
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    resolver: yupResolver(props.schema)
  });

  const [candidateEmail, setCandidateEmail] = useState()

  useEffect(() => {
    console.log(props.current_application_)
    console.log(props.row_info)
  }, [props.current_application_, props.row_info])

  const submit_meeting_create = () => {
    setValue('id_application', props.current_application_)
    setValue('id_state', 11)
    const data_ = getValues()
    props.submit_function(data_)
  }

  const submit_meeting_edit = () => {
    setValue('id_application', props.current_application_)
    const data_ = {
      'score'       : getValues('score'),
      'id_state'    : 12,
      'description' : getValues('description')
    }
    console.log(data_)
    props.submit_function(data_)
  }

  //  today_ = new Date();
  //  current_time = today_.toLocaleTimeString();

  const create_form = () => {
    return(     
      <form className="create_form" onSubmit={ handleSubmit(submit_meeting_create) }>
        <div className="modal-body">
          <div className="form-group row mt-3">
            <div className="col-4">
              <label className="col-12 form-label">Data</label>
              <input type="date" className="form-control" {... register("meeting_date")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors?.meeting_date?.message}</label>
            </div>
            <div className="col-4">
              <label className="col-12 form-label">Hora</label>
              <input type="time" className="form-control" {... register("start_time")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors.start_time?.message}</label>
            </div>
            <div className="col-4">
              <label className="col-12 form-label">Link Entrevista</label>
              <input type="text" className="form-control" {... register("meeting_link")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors.meeting_link?.message}</label>
            </div>
            <div className="col-12">
              <label className="col-12 form-label">Notas</label>
              <textarea className="form-control" {... register("description")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors.description?.message}</label>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn btn-primary">Submeter</button>
        </div>
      </form>
    )
  }

  const edit_form = () => {
    if(props?.row_info[0]?.state_name === "Terminado"){
      return(     
        <form className="create_form">
          <div className="modal-body">
            <div className="form-group row mt-3">
              <div className="col-3">
                <label className="col-12 form-label">Candidato</label>
                <input type='text' className="form-control" value={props?.row_info[0]?.candidate} disabled/>
              </div>
              <div className="col-3">
                <label className="col-12 form-label">Email do candidato</label>
                <input type='text' className="form-control" value={props?.row_info[0]?.candidate_email} disabled/>
              </div>
              <div className="col-3">
                <label className="col-12 form-label">Vaga</label>
                <input type='text' className="form-control" value={props?.row_info[0]?.job_offer_title} disabled/>
              </div>
              <div className="col-3">
                <label className="col-12 form-label">CV</label>
                <button className='col-12 btn btn-primary' onClick={() => window.open(props?.row_info[0]?.cv, "_blank")}>Ver Curriculo</button>
              </div>
            </div>
            <div className="form-group row mt-3">
              <div className="col-2">
                <label className="col-12 form-label">Classificação</label>
                <input type='number' className="form-control" value={props?.row_info[0]?.score} disabled/>
                <small>(Escala de 0 a 10)</small>
              </div>
              <div className="col-2">
                <label className="col-12 form-label">Data</label>
                <input type='date' className="form-control" value={props?.row_info[0]?.meeting_date} disabled/>
              </div>
              <div className="col-8">
                <label className="col-12 form-label">Notas</label>
                <textarea className="form-control" value={props?.row_info[0]?.description} disabled/>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">Submeter</button>
          </div>
        </form>
      )
    } else {
      return(     
        <form className="create_form" onSubmit={ handleSubmit(submit_meeting_edit) }>
          <div className="modal-body">
            <div className="form-group row mt-3">
              <div className="col-3">
                <label className="col-12 form-label">Candidato</label>
                <input type='text' className="form-control" value={props?.row_info[0]?.candidate} disabled/>
              </div>
              <div className="col-3">
                <label className="col-12 form-label">Email do candidato</label>
                <input type='text' className="form-control" value={props?.row_info[0]?.candidate_email} disabled/>
              </div>
              <div className="col-3">
                <label className="col-12 form-label">Vaga</label>
                <input type='text' className="form-control" value={props?.row_info[0]?.job_offer_title} disabled/>
              </div>
              <div className="col-3">
                <label className="col-12 form-label">CV</label>
                <button className='col-12 btn btn-primary' onClick={() => window.open(props?.row_info[0]?.cv, "_blank")}>Ver Curriculo</button>
              </div>
            </div>
            <div className="form-group row mt-3">
              <div className="col-2">
                <label className="col-12 form-label">Classificação</label>
                <input type='number' className="form-control" {... register("score")}/>
                <small>(Escala de 0 a 10)</small>
                <label className="form-label" style={ {'color': 'red'}}>{errors.score?.message}</label>
              </div>
              <div className="col-10">
                <label className="col-12 form-label">Notas</label>
                <textarea className="form-control" {... register("description")}/>
                <label className="form-label" style={ {'color': 'red'}}>{errors.description?.message}</label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">Submeter</button>
          </div>
        </form>
      )
    }
  }

  return(
     props.utility === 'create' ? create_form() : edit_form()
  )
}

export default MeetingForm;