import { useState, useEffect } from 'react';
import { useGetData } from '../hooks/useGetData';
import axios from 'axios';
import $ from 'jquery';

import { WorksLi } from './works_li';
import { JobOffersTabs } from './job_offers_tabs';
import { JobOffersTabsData } from './job_offers_tabs_data';

import logo_1 from '../template/img/com-logo-1.jpg';
import logo_2 from '../template/img/com-logo-2.jpg';
import logo_3 from '../template/img/com-logo-3.jpg';
import logo_4 from '../template/img/com-logo-4.jpg';
import logo_5 from '../template/img/com-logo-5.jpg';


export const Jobs = (props) => {


    const [allJobs, setAllJobs] = useState([]);
    const [allWorks, setAllWorks] = useState([]);
  
    //const { all_data: all_jobs } = useGetData('job_offers');
    //const { all_data: all_work_types } = useGetData('works');

    //  [] vazio    --> atualiza apena uma vez
    //  [] populado --> atualiza sempre que a váriavel muda
    //  quando unmounted  --> meter return ()=>{}
    useEffect(() => {
    }, [])

    /* if(isLoading_jobOffers){
        return  <div className="spinner-border text-primary" role="status" style={{ 'margin' : '5% 50%' }}>
                    <span className="sr-only"></span>
                </div>
    } */

    return(
    <div className="container-xxl py-5">
			<div className="container">
				<h1 className="text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">Lista de vagas</h1>
				<div className="tab-className text-center wow fadeInUp" data-wow-delay="0.3s">
					<ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-5">
						<WorksLi/>
					</ul>
					<div className="tab-content">             
						{/* <JobOffersTabs getCookieFn_={props.getCookieFn}/> */}
						<JobOffersTabsData getCookieFn_={props.getCookieFn}/>
					</div>
				</div>
			</div>
		</div>
  );
};