import axios from 'axios';
import { useState } from 'react';

import { TotalUsers } from './dashboard/totalUsers';
import { CandidatesByJobOffer } from './dashboard/candidatesByJobOffer';
import { OpportunitiesByState } from './dashboard/opportunitiesByState';
import { JobOffersByState } from './dashboard/jobOffersByState';
import { TotalUsersByRole } from './dashboard/totalUsersByRole';
import { CandidatesByOpportunity } from './dashboard/candidatesByOpportunity'


export const Dashboard = () => {
  
  const [file, setFile] = useState();
  
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("cv_file", file);

    console.log(file);
    axios.post("http://localhost:3008/api/applications/upload_cv", formData, {
      headers: {
        "content-type": "multipart/form-data",
      }
    })
    .then( (res) => {                  
      console.log(res.data);
      console.log(res.data.data.url);
      console.log(res.data.success);
    })
    .catch( (error) => {
      console.log(error);
    }) 
  };

  return(
    <div className="col-12 mt-5 dashboard" style={{textAlign:'center'}}>
      <h1 style={{'color':'#36659d'}}>Dashboard</h1>
      <TotalUsers/>

      <div className='mt-4'>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{'justifyContent':'center'}}>
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="vagas"
              data-bs-toggle="tab" data-bs-target="#vagas_tab" type="button" role="tab" aria-controls="home" aria-selected="true">
              Vagas
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="profile-tab" 
              data-bs-toggle="tab" data-bs-target="#opportunidades_tab" type="button" role="tab" aria-controls="profile" aria-selected="false">
              Oportunidades
            </button>
          </li>
        </ul>
        <div className="tab-content mt-4" id="pills-tabContent">
          <div className="tab-pane fade show active" id="vagas_tab" role="tabpanel" aria-labelledby="pills-home-tab">
          <div className="col-12 mt-3 card-group">
            <JobOffersByState/>
            <CandidatesByJobOffer/>    
          </div>
          </div>
          <div className="tab-pane fade" id="opportunidades_tab" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div className="col-12 mt-3 card-group">
              <OpportunitiesByState/>  
              <CandidatesByOpportunity/>  
            </div>
            </div>
        </div>
      </div>     
      <TotalUsersByRole/>    


      {/* <div className="col-12 mt-3 card-group">
        <CandidatesByJobOffer/>      
        <OpportunitiesByState/>   
      </div> */}
    </div>
  );
};