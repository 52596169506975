import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import $ from 'jquery';
import { useEffect, useState } from 'react';
import Select from 'react-select'

import { useGetData } from '../../hooks/useGetData';

const ContactForm = (props) => {

  const [stateObj, setStateObj] = useState({})
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [allOptions, setAllOptions] = useState([]);
  
  const { all_data, isLoading, haveError } = useGetData('applications_by_opportunity');

  var select_options =  [];
  
  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm({
    resolver: yupResolver(props.schema)
  });

  //    fazer get applications cuja id_opportunity = props.current_opportunity_.id_opportunity
  //    nessas apps que retornar, listar no select os respeivos users
  //      <option value="user.email">user.nome</option>
  //    mandar array dos resultados para api
  //    iterar sobre array, e para cada um, mandar email e criar contacto

  useEffect( () => {
    console.log(props.current_opportunity_?.id_opportunity)
    console.log(props.current_opportunity_?.name)
    setTableData(all_data)
    create_select_options(tableData)
    console.log(tableData)

  }, [props.current_opportunity_, all_data, tableData])

  //useGetDataById('applications', props.current_opportunity_.id_opportunity)

  const create_select_options = async (results) => {   
    console.log(results)
    var select_option = {};
    for( let i = 0; i < results?.length; i++ ){
      select_option = { id: results[i].id_user, value: results[i].user.email, label: results[i].user.first_name + ' ' + results[i].user.last_name }
      select_options.push(select_option);
    }
    console.log(select_options)
    setAllOptions(select_options)
  }

  const submit__ = () => {
    setValue('id_opportunity', props.current_opportunity_.id_opportunity)
    const opportunity_name = props.current_opportunity_.name
    var data_to_insert = []
    var emails_for_contact = []
    var ids_for_contact = []
    var bulk_data = []

    for( let i = 0; i < selectedEmails.length; i++){
      emails_for_contact.push(selectedEmails[i].value)
      ids_for_contact.push(selectedEmails[i].id)
      setValue('id_user', selectedEmails[i].id)
      bulk_data.push(getValues())
    }

    data_to_insert.push(bulk_data)
    data_to_insert.push(emails_for_contact)
    data_to_insert.push(ids_for_contact)
    data_to_insert.push({'opportunity_name' : opportunity_name})

    console.log(data_to_insert)
    props.submit_function(data_to_insert)
  }

  $('input.select__input').css('border','0px solid black !important')
  $('.select_emails input.select__input').css('border','0px solid black !important')


  return(     
    <form className="create_form" onSubmit={ handleSubmit(submit__) }>
      <div className="modal-body">
        <div className="form-group row mt-3">
          <div className="col-4">
            <label className="col-12 form-label">Email</label>
            <input type="text" className="form-control" {... register("email")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.email?.message}</label>
          </div>
          <div className="col-4">
            <label className="col-12 form-label">Data do contacto</label>
            <input type="date" className="form-control" {... register("contact_date")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.contact_date?.message}</label>
          </div>
          <div className="col-4">
            <label className="col-12 form-label">Contacto Telefónico</label>
            <input type="number" id="contact" className="form-control" {... register("phone_number")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.phone_number?.message}</label>
          </div>       
          <div className="select_emails col-4">
            <label className="col-12 form-label">Candidatos a contactar</label>
            <Select
              isMulti
              name="users_to_contact"
              options={allOptions}
              value={selectedEmails}
              onChange={setSelectedEmails}
              className="basic-multi-select"
              classNamePrefix="select"
              style={{'border':'0px solid black !important'}}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-primary">Submeter</button>
      </div>
    </form>
  )
}

export default ContactForm;