import { useState } from 'react';

import { BsFillPlusCircleFill } from 'react-icons/bs';
import { FaPen, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import * as yup from 'yup';

import { Navbar } from '../components/navbar';
import { Footer } from '../components/footer';
import { ModalCreate } from '../components/modal_create';
import { ModalEdit } from '../components/modal_edit';
import Table from '../components/table';

import { useDelete } from '../hooks/useDelete';
import { useGetDataById } from '../hooks/useGetDataById';


export const States = () => {

	const [deletionError, setDeletionError] = useState(null);

	const [rowObj, setRowObj] = useState({})

	const { mutate: delete_ } = useDelete(setDeletionError);
	const { mutate: getDataById_ } = useGetDataById();
	
  const states_table_columns = [
		{
			Header: "Id",           
			accessor: "id_state"     
		},
		{
			Header: "Nome", 
			accessor: "name"
		}
	];

	const schema = yup.object().shape({
    name  				: yup.string().required("Preencher!")
  });

	function getDataByID(table_name, id){
		const data_obj = {table_name: table_name, id: id, modal_title: "Estados"};
		getDataById_(data_obj);
	}

	function deleteData(table_name, id){

		const data_obj = {table_name: table_name, id: id};

		Swal.fire({
			title: 'Tem a certeza?',
			text: 'Não poderá recuperar este ficheiro!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Confirmar'
		}).then((result) => {
				if(result){
					delete_(data_obj);
				}else if(result.dismiss === Swal.DismissReason.cancel){
					Swal.fire(
						'Cancelado',
						'Operação cancelada!',
						'error'
					)
				}
		}) 
	};

  return( 
		<div className="container-xxl bg-white p-0">
			<Navbar/>
			<div className='row'>
				<div className='col-8 offset-2 mt-5'>
					<h1 style={{'textAlign' : 'center'}}>
						Estados
					</h1>					
					<Table table_name="states" table_columns={states_table_columns}/>
				</div>
			</div>
			<Footer/>
			<ModalCreate table_name="states" modal_title="Estados" schema={schema}/>
			<ModalEdit table_name="states" modal_title="Estados" schema={schema} row_data={rowObj}/>
		</div>
  );
};