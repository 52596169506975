import axios from 'axios';
import $ from 'jquery';
import { useMutation } from '@tanstack/react-query';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export const useEdit = () => {

  return useMutation((data_obj) => {

    let table_name = data_obj.table_name;
    let id = data_obj.id;
    console.log(table_name);
    console.log(id);
    console.log(data_obj.data);

    return axios.put("https://pint-22-23-backend.onrender.com/api/"+table_name+"/edit/"+id, data_obj.data)
                .then((res) => {                                      
                  if(res.data.success){
                    $('.create_form')[0].reset();  
                    return Swal.fire({
                            title: res.data.message,
                            icon: 'success'
                          })
                  }else{    
                    return Swal.fire({
                            title: res.data.message,
                            icon: 'error'
                          })
                  } 
                })
                .catch((error) => {
                  return Swal.fire({
                          title: 'Erro!',
                          icon: 'error'
                        }) 
                })
  })
}