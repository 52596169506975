import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export const returnDataByID = (data_obj) => {
  
  let table_name = data_obj.table_name;
  let id = data_obj.id;

  //http://localhost:3008/api/
  return axios.get("https://pint-22-23-backend.onrender.com/api/"+table_name+"/"+id)
        .then((res) => {                                      
          if(res.data.success){
            return res.data.data;
          }else{    
            return Swal.fire({
                    title: res.data.message,
                    icon: 'error'
                  })
          }        
        })
        .catch((error) => { 
          return Swal.fire({
                  title: 'Erro!',
                  icon: 'error'
                }) 
        })
}