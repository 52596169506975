import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export const useGetData = ( table_name ) => {

  function getCookie(cookieName) {
		let cookie = {};
		document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
		})
		return cookie[cookieName];
	}

  var url = "";

  if( table_name !== 'applications_by_users' ){
    url = "https://pint-22-23-backend.onrender.com/api/"+table_name
  } else {
    url = "https://pint-22-23-backend.onrender.com/api/"+table_name+"/"+getCookie('user_id');
  }

  console.log(url)
  
  const { data: all_data, isLoading, haveError } = useQuery(["all_data"], async () => {
    return await axios.get(url)
                      .then((res) => {                                     
                        if(res.data.success){
                          return res.data.data;
                        }else{    
                          return Swal.fire({
                                  title: res.data.message,
                                  icon: 'warning'
                                })
                        } 
                      })
                      .catch((error) => {
                        return Swal.fire({
                                title: 'Sem dados!',
                                icon: 'error'
                              }) 
                      })
  });

  return { all_data, isLoading, haveError }; 
}
