import $ from 'jquery';
import { useEffect, useState } from 'react';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { FaPen, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import * as yup from 'yup';

import { Navbar } from '../components/navbar';
import { Footer } from '../components/footer';
import { ModalCreate } from '../components/modal_create';
import { ModalEdit } from '../components/modal_edit';
import Table from '../components/table';

import { useDelete } from '../hooks/useDelete';
import { useGetDataById } from '../hooks/useGetDataById';
import { returnDataByID } from '../hooks/getDataById';
import { useMutation } from '@tanstack/react-query';


export const JobOffers = ({isLoggedIn}) => {

	const [deletionError, setDeletionError] = useState(null);
	const [dataById, setDataById] = useState(null);
	const [byID, setByID] = useState({});
	const [rowObj, setRowObj] = useState();
	const { mutate: delete_ } = useDelete(setDeletionError);
	//const { mutate: getDataById_, data: returned_user, isSuccess } = useGetDataById();

	useEffect( () => {

	}, [])


	const job_offers_table_columns = [
		{
			Header: "Id",               //label
			accessor: "id_joboffer"     //name from api
		},
		{
			Header: "Titulo", 
			accessor: "title"
		},
		{
			Header: "Email", 
			accessor: "email"
		},
		{
			Header: "Contacto",
			accessor: "contact"    
		},
		{
			Header: "Localidade", 
			accessor: "requirements"    
		},
		{
			Header: "Estado", 
			accessor: "state.name"    
		},
		{
			Header: "Opções",
			Cell: ({row}) => (
				<>
					{/* <FaPen onClick={ () => editData('users', 22) } value={{ "height": "1em", "width": "1em" }} style={{ "marginRight": "10px", "cursor": "pointer", "color": "#36659d" }} /> */}
					<FaPen 
						data-bs-toggle="modal" 
						data-bs-target="#edit" 
						onClick={ () => getDataByID('job_offers', row.values.id_joboffer) }
						//onClick={ () => setRowObj(row.values) }
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginRight": "10px", "cursor": "pointer", "color": "#36659d" }} 
					/>
					<FaTrash 
						className="lixo_" 
						onClick={ () => deleteData('job_offers', row.values.id_joboffer) } 
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginLeft": "10px", "cursor": "pointer", "color": "#36659d" }} />
				</>
			),  
		}
	];

	const schema = yup.object().shape({
    title  			: yup.string().required("Preencher!"),
    email       : yup.string().email().required("Preencher!"),
    contact     : yup.number().positive().integer().min(9).required("Preencher!"),
    soft_skills : yup.string().required(),        
    hard_skills : yup.string().required(),        
    description : yup.string().required()
  });

	async function getDataByID(table_name, id){
		const data_obj = {table_name: table_name, id: id};
		const data__byId = await returnDataByID(data_obj);
		setRowObj(data__byId);
	}

	function deleteData(table_name, id){

		const data_obj = {table_name: table_name, id: id};

		Swal.fire({
			title: 'Tem a certeza?',
			text: 'Não poderá recuperar este ficheiro!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Confirmar'
		}).then((result) => {
				if(result){
					delete_(data_obj);
				}else if(result.dismiss === Swal.DismissReason.cancel){
					Swal.fire(
						'Cancelado',
						'Operação cancelada!',
						'error'
					)
				}
		}) 
	};

  return( 
		<div className="container-xxl bg-white p-0">
			<Navbar/>
			<div className='row'>
				<div className='col-8 offset-2 mt-5'>
					<h1 style={{'textAlign' : 'center'}}>
						Vagas
						<i style={{ paddingLeft: '15px', color: '#36659d' }}>
							<BsFillPlusCircleFill 
								value={{ "height": "2em", "width": "2em" }} 
								style={{ cursor: 'pointer' }} 
								data-bs-toggle="modal" 
								data-bs-target="#create"/>
						</i>
					</h1>					
					<Table table_name="job_offers" table_columns={job_offers_table_columns}/>
				</div>
			</div>
			<Footer/>
			<ModalCreate table_name="job_offers" modal_title="Vagas" schema={schema}/>
			<ModalEdit table_name="job_offers" modal_title="Vagas" schema={schema} row_data={rowObj}/>
		</div>
  );
}