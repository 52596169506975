import { BiLogOut } from 'react-icons/bi';
import { logout } from '../auth';

export const LogoutBtn = (props) => {
  return(
    <a href="#" 
      className="back-to-top arrow-top" 
      onClick={ () => { 
        props.dispatch_(logout()); 
        props.changeLoggedIn(false);
      }} >
      <i className='icon_tag'>
        <BiLogOut style={{ width: '1.5em', height: "auto" }}/>
        </i>
    </a>
  );
};