import { useState } from 'react';

import { BsFillPlusCircleFill } from 'react-icons/bs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import * as yup from 'yup';

import { Navbar } from '../components/navbar';
import { Footer } from '../components/footer';
import { ModalCreate } from '../components/modal_create';
import Table from '../components/table';


export const MyApplications = () => {

  const my_applications_table_columns = [
		{
			Header: "Id",           
			accessor: "id_user"     
		},
		{
			Header: "Nome", 
			accessor: "name"
		},
		{
			Header: "Email", 
			accessor: "email"
		},
		{
			Header: "Titulo", 
			accessor: "opportunity",
      Cell: ({row}) => { 
        return (
          <div>
            <span>{row.values.opportunity?.name} {row.values.job_offer?.title}</span>
          </div>
        )
      }
		}
	];

  return( 
		<div className="container-xxl bg-white p-0">
			<Navbar/>
			<div className='row'>
				<div className='col-8 offset-2 mt-5'>
					<h1 style={{'textAlign' : 'center'}}>
						As minhas candidaturas
					</h1>					
					<Table table_name="applications_by_users" table_columns={my_applications_table_columns}/>
				</div>
			</div>
			<Footer/>
		</div>
  );

}