import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';


const getDataById = (data_obj) => {

  let table_name = data_obj.table_name;
  let id = data_obj.id;

  axios.get("https://pint-22-23-backend.onrender.com/api/"+table_name+"/"+id)
        .then((res) => {                                    
          if(res.data.success){
            return res.data.data;
          }else{    
            return Swal.fire({
                    title: res.data.message,
                    icon: 'error'
                  })
          }         
        })
        .catch((error) => {  
          return Swal.fire({
                  title: 'Erro!',
                  icon: 'error'
                })   
        })
}

export const useGetDataById = () => {

  const [dataa__, setDataa__] = useState();

  return useMutation({    
    mutationFn: async (data_obj) =>{
      const dataa_ = await getDataById(data_obj);
      setDataa__(dataa_)
      return dataa_;
    },
    onSuccess: () => {
      return Swal.fire({
              title: 'Sucesso!',
              icon: 'success'
            })
    }
  })

}
