import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import $ from 'jquery';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

const ApplicationForm = (props) => {

  const [fileUploaded, setFileUploaded] = useState()

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    noDrag: true, 
    maxFiles: 1,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc']
    }
  });
  
  const { register, unregister, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    resolver: yupResolver(props.schema)
  });

  useEffect( () => {
    if( JSON.stringify(props.row_info) !== '{}' && props.utility === 'edit' && props.current_opportunity_ === 0 ){
      console.log(props.row_info[0])
      setValue('name',                props.row_info.name)
      setValue('email',               props.row_info.email)
      setValue('contact',             props.row_info.contact)
      setValue('cv',                  props.row_info.cv)
      setValue('resume',              props.row_info.resume)
      setValue('salary_expectation',  props.row_info.salary_expectation)
      setValue('description',         props.row_info.description)        
    }
    if(props.utility === 'create' && props.current_opportunity_ == '0'){
      setValue('id_joboffer', props.current_job_offer_)
      setValue('id_opportunity', null)
      console.log(getValues('id_joboffer'))
      console.log(getValues('id_opportunity'))
    } else {
      setValue('id_opportunity', props.current_opportunity_)
      setValue('id_joboffer', null)
    }
    setValue('id_user',             props.current_user_)            
  }, [props?.row_info, setValue, props.current_user_, props.current_job_offer_, props.current_opportunity_])

  /* const files = acceptedFiles.map(file => (    
    <p key={file.path}>{file.path} - {file.size} bytes</p>   
  )); */

  const file_data = (e) => {
    $('.file_input_name').text(e.target.files[0]?.name)
    setFileUploaded(e.target.files[0]); 
  }

  const create_new_application_job_offer = async () => {
    const formData = new FormData();
    formData.append('cv_file', fileUploaded);
    await axios.post("https://pint-22-23-backend.onrender.com/api/applications/upload_cv", formData, {
      headers: {
        "content-type": "multipart/form-data",
      }
    })
    .then( (res) => {     
      if( res.data.success ){     
        setValue('cv', res.data.data.url)
        if( res.data.data.url.length > 0 ){
          setValue('id_joboffer', 5)
		      let data_ = getValues();
          console.log(data_)
          props.submit_function(data_)   
        }
      } else {
        return  Swal.fire({
                  title: 'Erro!',
                  text: 'Erro ao carregar ficheiro!',
                  icon: 'danger'
                })
      }
    })
    .catch( () => {
      return  Swal.fire({
                title: 'Erro!',
                text: 'Erro ao carregar ficheiro!',
                icon: 'danger'
              })
    }) 
  }

  const create_new_application_opportunity = async () => {
    unregister('resume')
    unregister('salary_expectation')
    props.submit_function( getValues() )  
  }

  if( props.current_opportunity_ !== 0 ){
    return(
      <form className="create_form" onSubmit={ handleSubmit(create_new_application_opportunity) }>
        <div className="modal-body">
          <div className="form-group row mt-3">
            <div className="col-4">
              <label className="col-12 form-label">Nome</label>
              <input type="text" className="form-control" {... register("name")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors?.name?.message}</label>
            </div>
            <div className="col-4">
              <label className="col-12 form-label">Email</label>
              <input type="email" className="form-control" {... register("email")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors?.email?.message}</label>
            </div>
            <div className="col-4">
              <label className="col-12 form-label">Contacto</label>
              <input type="number" id="contact" className="form-control" {... register("contact")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors?.contact?.message}</label>
            </div>
          </div>
          <div className="form-group row mt-3">
            <div className="col-4">
              <label className="col-12 form-label">Notas</label>
              <input type="text" className="form-control" {... register("description")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors?.description?.message}</label>
            </div>     
          </div>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn btn-primary">Submeter</button>
        </div>
      </form>
    )
  } else {
    return(
      <form className="create_form" onSubmit={ handleSubmit(create_new_application_job_offer) }>
        <div className="modal-body">
          <div className="form-group row mt-3">
            <div className="col-4">
              <label className="col-12 form-label">Nome</label>
              <input type="text" className="form-control" {... register("name")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors?.name?.message}</label>
            </div>
            <div className="col-4">
              <label className="col-12 form-label">Email</label>
              <input type="email" className="form-control" {... register("email")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors?.email?.message}</label>
            </div>
            <div className="col-4">
              <label className="col-12 form-label">Contacto</label>
              <input type="number" id="contact" className="form-control" {... register("contact")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors?.contact?.message}</label>
            </div>
          </div>

          <div className="form-group row mt-3">
            <div className="col-4">
              <label className="col-12 form-label">Expetativas salariais</label>
              <input type="number" className="form-control" {... register("salary_expectation")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors?.salary_expectation?.message}</label>
            </div>
            <div className="col-4">
              <label className="col-12 form-label">Notas</label>
              <input type="text" className="form-control" {... register("description")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors?.description?.message}</label>
            </div>      
            <div className='col-4'>  
              <label className="col-12 form-label">CV</label>
              <section className="dropzone_file">
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} onChange={ (e) => { file_data(e) }} />
                  <p className='file_input_name'>Adicionar ficheiro</p>
                </div>
              </section>
              <label className="form-label" style={ {'color': 'red'}}>{errors?.cv?.message}</label>
            </div>  
          </div>

          <div className="form-group row mt-3">
            <div className="col-8 offset-2">
              <label className="col-12 form-label">Carta de Apresentação</label>
              <textarea type="text" rows={3} style={{'resize':'none'}} className="form-control" {... register("resume")}/>
              <label className="form-label" style={ {'color': 'red'}}>{errors?.resume?.message}</label>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn btn-primary">Submeter</button>
        </div>
      </form>
    )
  }

}

export default ApplicationForm;