import { Navigate, Outlet } from "react-router-dom";

/* const useAuth = (loggedIn = false) => {
  const user = { loggedIn };
  return user && user.loggedIn;
};

const ProtectedRoutes = (props) => {
  console.log(props.loggedIn_)
  const isAuth = useAuth(props.loggedIn_);
  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes; */

export const ProtectedRoutes = ({
  isAllowed,
  redirectPath = '/login',
  children,
}) => {
  if (isAllowed === false) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};