import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormSelect, FormCheck } from "react-bootstrap";

import $ from 'jquery';

import { useEdit } from '../../hooks/useEdit';
import { useGetData } from '../../hooks/useGetData';
import { useCreate } from '../../hooks/useCreate';

const UserForm = (props) => {

  const { mutate: edit_ } = useEdit();
  const { mutate: create_ } = useCreate();

  /* const onSubmit = (data) => {
		const data_obj = {table_name: props.table_name, data: data, id: props.id};
		edit_(data_obj);
  }; */

  const { all_data: all_roles } = useGetData('roles');

  const [stateObj, setStateObj] = useState({})
  const [firstName_, setFirstName__] = useState('')
  const [lastName_, setLastName__] = useState('')
  const [email_, setEmail_] = useState('')
  const [contact_, setContact_] = useState()
  const [birthDate_, setBirthDate_] = useState()
  const [city_, setCity_] = useState('')
  const [role_, setRole_] = useState()
  const [state_, setState_] = useState(false)
  const [roles_, setRoles_] = useState()

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    resolver: yupResolver(props.schema)
  });

  useEffect( () => {
    setValue('first_name', props?.row_info?.first_name)
    setValue('last_name', props?.row_info?.last_name)
    setValue('email', props?.row_info?.email)
    setValue('contact', props?.row_info?.contact)
    setValue('birth_date', props?.row_info?.birth_date)
    setValue('city', props?.row_info?.city)
    setValue('id_role', 6)
    setState_(props?.row_info?.is_active)
    setRoles_(all_roles)
  }, [props?.row_info, all_roles, roles_, setValue])


  console.log(roles_);

  if( typeof(roles_) != 'undefined' ){
    var html = '';
    $('#roles_create_').empty().append('<option selected="selected" value="0">Escolha um cargo</option>');
    $('#roles_edit_').empty().append('<option selected="selected" value="0">Escolha um cargo</option>');
    for( let i = 0; i < roles_.length; i++ ){
      html += `<option value="`+roles_[i].id_role+`">`+roles_[i].name+`</option>`;
    }
    console.log(html);
    $('#roles_create_').append(html);
    $('#roles_edit_').append(html);
  }

  const submit_ = () => {
		const data_obj = {table_name: props.table_name, data: getValues()};
    if(props.utility === 'edit'){
      data_obj.id = props?.row_info?.id_user;
    }
    console.log(data_obj);
    props.utility === 'edit' ? edit_(data_obj) : create_(data_obj);
  }

  const onSwitchAction = () => {
    setState_(!state_);
    console.log(state_);
  };

  return(     
    <form className="create_form" onSubmit={ handleSubmit(submit_) }>
      <div className="modal-body">
        <div className="form-group row mt-3">
          <div className="col-4">
            <label className="col-12 form-label">Primeiro Nome</label>
            <input type="text" id="first_name" className="form-control" placeholder="Luís" {... register("first_name")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.first_name?.message}</label>
          </div>
          <div className="col-4">
            <label className="col-12 form-label">Último Nome</label>
            <input type="text" id="last_name"className="form-control" placeholder="Costa" {... register("last_name")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors.last_name?.message}</label>
          </div>
          <div className="col-4">
            <label className="col-12 form-label">Email</label>
            <input type="email" id="email" className="form-control" placeholder="nome@examplo.com" {... register("email")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors.email?.message}</label>
          </div>
        </div>
        <div className="form-group row mt-5">
          <div className="col-3">
            <label className="col-12 form-label">Contacto</label>
            <input value={props?.row_info?.contact} type="number" id="contact" className="form-control" placeholder="912345678" {... register("contact")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors.contact?.message}</label>
          </div>
          <div className="col-3">
            <label className="col-12 form-label">Data de Nascimento</label>
            <input type="date" id="birth_date" className="form-control" min="1965-01-01" max="2006-12-31" value={props?.row_info?.birth_date} {... register("birth_date")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors.birth_date?.message}</label>
          </div>
          <div className="col-3">
            <label className="col-12 form-label">Cidade</label>
            <input type="text" className="form-control" id="city" placeholder="Viseu" {... register("city")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors.city?.message}</label>
          </div>
          <div className="col-3">
            <label className="col-12 form-label">Cargo</label>
            <FormSelect id={props.utility === 'edit' ? "roles_edit_" : "roles_create_"} {... register("id_role")}>              
            </FormSelect>
          </div>
          <div className="col-3">
            <label className="col-12 form-label">Estado</label>
            <FormCheck 
              id="is_user_active"
              type="switch"
              label={state_ === true ? 'Ativo' : 'Inativo'}
              defaultChecked={state_ === true ? 'true' : 'false'}
              checked={state_}
              value={state_ === true ? true : false}                    
              {...register('is_active', {
                onChange: () => {onSwitchAction()}
              })}
            />
          </div>
        </div>  
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-primary">Submeter</button>
      </div>
    </form>
  )
}

export default UserForm;