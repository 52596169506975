import { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import '../template/css/login.css';


export const ActivateAccount = () => {

  const [email, setEmail] = useState('')
  
	let navigate = useNavigate(); 

	useEffect(() => {
		setTimeout(() => {
			document.getElementById('container').classList.add('login_sign-in')
			document.querySelector('html').style.height = '100vh';
		}, 200)
    
    let url = window.location.href;
    if(url.includes('?')){
      setEmail( url.split('=')[1] );
      console.log(email)
    }else{
      console.log('No Parameters in URL');
    }
	}, []); 

  const activate_account = () => {
    console.log(email)
    axios.post("https://pint-22-23-backend.onrender.com/api/activate_account/"+ email)
          .then((res) => {
            if( res.data.success ){
							Swal.fire({
								title: res.data.message,
								icon: 'success',
								allowOutsideClick: false,
								allowEscapeKey: false,
								confirmButtonText: 'Ir para Login'
							}).then((result) => {
								if (result.isConfirmed) {
									navigate('/');
								}
							})              
            } else {
              return Swal.fire({
                title: 'Erro!',
                text: 'Erro ao ativar conta!',
                icon: 'error'
              })
            }
          })
          .catch(() => {
						return Swal.fire({
							title: 'Erro!',
							text: 'Erro ao ativar conta!',
							icon: 'error'
						})
					})	
  }

  
  return( 
		<div id="container" className="login_container">

			<div className="login_row">
        
				<div id="register" className="login_col login_align-items-center login_flex-col login_sign-up">				
				</div>

				<div id="login" className="login_col login_align-items-center login_flex-col login_sign-in">
					<div className="login_form-wrapper login_align-items-center">
						<div className="login_form login_sign-in">
							<h3>Está a um passo de ativar a sua conta!</h3>
							<button onClick={() => activate_account()}>Ativar conta e ir login</button>
						</div>
					</div>
				</div>
			</div>

			<div className="login_row login_content-row">
      <div className="login_col login_align-items-center login_flex-col">
					<div className="login_img login_sign-in">	
						<img 	alt="" width={'450px'} style={{'background': 'white', 'borderRadius': '10px'}}
									src="https://www.neptune-software.com/wp-content/uploads/2017/11/logotipo_softinsa_2016.png" />	
					</div>
					<div className="login_text login_sign-in">
						<h2 style={{'fontSize':'45px'}}>Business Solutions</h2>		
					</div>
				</div>
			</div>
		</div>
  );
};
