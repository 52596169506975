import { TbHexagonLetterA } from 'react-icons/tb';
import { TbHexagonLetterB } from 'react-icons/tb';
import { TbHexagonLetterC } from 'react-icons/tb';
import { TbHexagonLetterD } from 'react-icons/tb';
import { TbHexagonLetterE } from 'react-icons/tb';
import { TbHexagonLetterF } from 'react-icons/tb';
import { TbHexagonLetterG } from 'react-icons/tb';
import { TbHexagonLetterH } from 'react-icons/tb';
import { TbHexagonLetterI } from 'react-icons/tb';
import { TbHexagonLetterJ } from 'react-icons/tb';
import { TbHexagonLetterK } from 'react-icons/tb';
import { TbHexagonLetterL } from 'react-icons/tb';
import { TbHexagonLetterM } from 'react-icons/tb';
import { TbHexagonLetterN } from 'react-icons/tb';
import { TbHexagonLetterO } from 'react-icons/tb';
import { TbHexagonLetterP } from 'react-icons/tb';
import { TbHexagonLetterQ } from 'react-icons/tb';
import { TbHexagonLetterR } from 'react-icons/tb';
import { TbHexagonLetterS } from 'react-icons/tb';
import { TbHexagonLetterT } from 'react-icons/tb';
import { TbHexagonLetterU } from 'react-icons/tb';
import { TbHexagonLetterV } from 'react-icons/tb';
import { TbHexagonLetterW } from 'react-icons/tb';
import { TbHexagonLetterX } from 'react-icons/tb';
import { TbHexagonLetterY } from 'react-icons/tb';
import { TbHexagonLetterZ } from 'react-icons/tb';

export const JobsIcons = (props) => {

  switch(props.first_letter){
    case 'A':
      return <TbHexagonLetterA style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'B':
      return <TbHexagonLetterB style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'C':
      return <TbHexagonLetterC style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'D':
      return <TbHexagonLetterD style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'E':
      return <TbHexagonLetterE style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'F':
      return <TbHexagonLetterF style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'G':
      return <TbHexagonLetterG style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'H':
      return <TbHexagonLetterH style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'I':
      return <TbHexagonLetterI style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'J':
      return <TbHexagonLetterJ style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'K':
      return <TbHexagonLetterK style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'L':
      return <TbHexagonLetterL style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'M':
      return <TbHexagonLetterM style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'N':
      return <TbHexagonLetterN style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'O':
      return <TbHexagonLetterO style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'P':
      return <TbHexagonLetterP style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'Q':
      return <TbHexagonLetterQ style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'R':
      return <TbHexagonLetterR style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'S':
      return <TbHexagonLetterS style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'T':
      return <TbHexagonLetterT style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'U':
      return <TbHexagonLetterU style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'V':
      return <TbHexagonLetterV style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'W':
      return <TbHexagonLetterW style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'X':
      return <TbHexagonLetterX style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'Y':
      return <TbHexagonLetterY style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    case 'Z':
      return <TbHexagonLetterZ style={{width: '80px', height: '80px', color: '#36659d'}}/>;
    default:
      return <TbHexagonLetterS style={{width: '80px', height: '80px', color: '#36659d'}}/>;
  }
};