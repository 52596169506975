import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import axios from 'axios';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement
)

export const CandidatesByOpportunity = () => {

  const [opportunities, setOpportunities] = useState([])
  const [applicationsPerOpportunity, setApplicationsPerOpportunity] = useState([])


  useEffect(() => {
    const candidates_by_opportunity = async () => {
      await axios.get("https://pint-22-23-backend.onrender.com/api/opportunities")
      .then((res) => {
        console.log('number -', res.data.data.length)
        setOpportunities(res.data.data)
      })
      .catch((error) => {
        console.log(error)
      })  
    }
    const applications_per_opportunity = async () => {
      await axios.get("https://pint-22-23-backend.onrender.com/api/dashboard/applications_per_opportunity")
      .then((res) => {
        setApplicationsPerOpportunity(res.data.data)
      })
      .catch((error) => {
        console.log(error)
      })  
    }
    candidates_by_opportunity()
    applications_per_opportunity()
  }, [])




  var data_ = {
    labels: opportunities.length > 0 ? opportunities?.map(x => x.name) : '',
    datasets: [{
      data: applicationsPerOpportunity.length > 0 ? applicationsPerOpportunity?.map(x => x.number_applications) : '',
      borderWidth: 1
    }]
  }

  var options= {
    maintainAspectRatio: true,
    scales: {
      yAxes:[{
        ticks: {
            stepSize: 1,
            beginAtZero: true,
            display: true,
            precision: 0
        }
      }]
    }
  }

  return(
    <div className="card col-3" style={{'border':'4px solid #36659d', 'borderRadius':'7px','color':'#36659d'}}>
      <div className="card-body mb-3 " style={{'height':'400px'}}>
        <h5 className="card-title">Nº Candidaturas por Oportunidade</h5>
        <Bar
          data={data_}
          height={400}
          options={options}/>          
      </div>
    </div>
  )

}