import { configureStore, createSlice } from '@reduxjs/toolkit';

import ProtectedRoutes from "./protectedRoutes";

const initialState = {
	value: { email: "" } 
};

const userSlice = createSlice({
	name: "user",							//nome
	initialState,
	reducers: {								//ações a utilizar
		login: (state, action) => {
			state.value = action.payload;
		},
		logout: (state) => {
			document.cookie = "loggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
			document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
			document.cookie = "role=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
			document.cookie = "user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
			state.value = initialState.value;			
			let path = window.location.origin+`/login`; 
			window.location.replace(path);
		}
	}
});

export const { login, logout } = userSlice.actions;

export const store = configureStore({			//aqui passa-se como reducer o que está em cima (userSlice, nome user)
	reducer: {
		user: userSlice.reducer
	}
});