import "../template/css/table.css";

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTable, useSortBy, usePagination } from 'react-table';
import { useMemo, useState, useEffect } from 'react';
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import axios from 'axios';

import  { useGetData } from '../hooks/useGetData';

//import { ArrowRight } from 'react-bootstrap-icons';

import { FaPen, FaTrash, FaDotCircle, FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { FcPrevious, FcNext } from 'react-icons/fc';
import { AiOutlineTeam } from 'react-icons/ai';
import { GrDocumentText } from 'react-icons/gr';
import { boolean } from "yup";

const TableQuery = (props) => {

  const [tableData, setTableData] = useState(null);


	function getCookie(cookieName) {
		let cookie = {};
		document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
		})
		return cookie[cookieName];
	}

  const { all_data, isLoading, haveError } = useGetData(props.table_name);

  //  [] vazio    --> atualiza apena uma vez
  //  [] populado --> atualiza sempre que a váriavel muda
  //  quando unmounted  --> meter return ()=>{}
  useEffect(() => {

    console.log('Component Mounted');

    setTableData(all_data);
  
    console.log(all_data);

    return () => {
      console.log('Component Unmounted');
    };
  }, [all_data])

  if (isLoading || !tableData) {    
    return  <div className="spinner-border" role="status" style={{ 'color':'#36659d', 'margin':'5% 50%' }}>
              <span className="sr-only"></span>
            </div>
  }

  if (haveError || !tableData) {
    return  <>
              <h2>An error occured. Please leave a comment describing the error, so an administrator can fix it!</h2>
              <div className="mb-3">
                <textarea className="form-control" placeholder="Please describe the problem as best as you can here!" id="error_textarea" rows="2"></textarea>
              </div>
            </>
  }

  return (
    tableData.length >= 1 ? <TableInstance tableData={tableData} table_columns={props.table_columns}/> : <h4 style={{'marginTop':'40px', 'textAlign':'center'}}>Sem candiaturas efetuadas!</h4>
  );
}

const TableInstance = ({ tableData, table_columns }) => {

  const [columns, data] = useMemo( () => {
    return [table_columns, tableData];
  }, [tableData]); 
  //  segundo argumento é um [] de dependencia do useMemo hook, ou seja, apenas é atualizado quando a dependencia ([tabledata]) muda

  const tableInstance = useTable({ columns, data }, useSortBy, usePagination);

  return (
    <TableLayout {...tableInstance} />
  );
}
  
const TableLayout = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  page,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  state: { pageIndex, pageSize }
}) => {
  return (
    <>
    <table className="table" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th style={{textAlign: "center"}} {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted 
                    ? (column.isSortedDesc ? <FaArrowDown/> : <FaArrowUp/> ) 
                    : '' 
                  }
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr style={{textAlign: "center"}} {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    { typeof(cell.value) == 'boolean' && cell.value === true  ? <FaDotCircle value={cell.value} color="green" /> : '' }
                    { typeof(cell.value) == 'boolean' && cell.value === false ? <FaDotCircle value={cell.value} color="red" /> : '' }
                    { typeof(cell.value) == 'string' && cell.value?.indexOf('http') !== -1
                      ? (cell.value?.indexOf('cloudinary') === -1 
                          ? <a target="_blank" rel="noreferrer">
                              <AiOutlineTeam 
                              value={{ "height": "1em", "width": "1em" }}
                              style={{ "marginRight": "10px", "cursor": "pointer" }}
                              onClick={ () => { navigator.clipboard.writeText(cell.value); 
                                                Toastify({
                                                  text: "Link da entrevista copiado",   
                                                  position: "center",                         
                                                  duration: 3000                            
                                                }).showToast()}
                                }/> 
                            </a>
                          : <a href={cell.value} target="_blank" rel="noreferrer">
                              <GrDocumentText 
                              value={{ "height": "1em", "width": "1em" }}
                              style={{ "marginRight": "10px", "cursor": "pointer" }}/> 
                            </a>)                    
                      : cell.render('Cell')
                    }
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div className="pagination">
      <div>
        <FcPrevious onClick={() => previousPage()} disabled={!canPreviousPage}/>
        <FcNext onClick={() => nextPage()} disabled={!canNextPage}/>
      </div>
      {" "}
      <span style={{'marginLeft':'20px'}}>
        Página nº {" "}
        <strong style={{'color':'#36659d'}}>
          {pageIndex + 1} of {pageOptions.length}
        </strong>{" "}
      </span>
      <select
        style={{'marginLeft':'20px', 'border':'3px solid #36659d', 'borderRadius':'3px'}}
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>
    </div>
    </>
  );
}
  
const client = new QueryClient();

const Table = (props) => {
  return (
    <QueryClientProvider client={client}>
      <TableQuery table_name={props.table_name} table_columns={props.table_columns}/>
    </QueryClientProvider>
  );
}

export default Table;