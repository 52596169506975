import { useState } from 'react';
import * as yup from 'yup';
import $ from 'jquery';

import { BsFillPlusCircleFill } from 'react-icons/bs';
import { FaPen, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

import Table from '../components/table';
import { Navbar } from '../components/navbar';
import { Footer } from '../components/footer';
import { ModalCreate } from '../components/modal_create';
import { ModalEdit } from '../components/modal_edit';
import { LogoutBtn } from '../components/logout_btn';

import { useDelete } from '../hooks/useDelete';
import { useGetDataById } from '../hooks/useGetDataById';


export const ApplicationsFromOpportunities = () => {

	const [deletionError, setDeletionError] = useState(null);

	const [rowObj, setRowObj] = useState({})

	const { mutate: delete_ } = useDelete(setDeletionError);
	const { mutate: getDataById_ } = useGetDataById();
	
  const applications_table_columns = [
		{
			Header: "Id",           
			accessor: "id_application"     
		},
		{
			Header: "Vaga", 
			accessor: "opportunity.name"
		},
		{
			Header: "Candidato", 
			accessor: "user",
      Cell: ({row}) => { 
        return (
          <div>
            <span className="candidate_" data-id_user={row.values.id_user}>{row.values.user?.first_name} {row.values.user?.last_name}</span>
          </div>
        )
      }
		},
		{
			Header: "Opções",
			Cell: ({row}) => (
				<>
					<FaPen 
						data-bs-toggle="modal" 
						data-bs-target="#edit" 
						onClick={ () => { console.log(row.values) }}
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginRight": "10px", "cursor": "pointer", "color": "#36659d" }} 
					/>
					<FaTrash 
						className="lixo_" 
						onClick={ () => deleteData('states', row.values.id_application) } 
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginLeft": "10px", "cursor": "pointer", "color": "#36659d" }} 
					/>
				</>
			),  
		}
	];

	const schema_application = yup.object().shape({
    name    : yup.string().required("Preencher!"),
    email   : yup.string().email().required("Preencher!"),
    contact : yup.number().required("Preencher!"),
    cv      : yup.string().required("Preencher!")
  });

	function getDataByID(table_name, id){
		const data_obj = {table_name: table_name, id: id, modal_title: "Candidaturas"};
		getDataById_(data_obj);
	}

	function deleteData(table_name, id){

		const data_obj = {table_name: table_name, id: id};

		Swal.fire({
			title: 'Tem a certeza?',
			text: 'Não poderá recuperar este ficheiro!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Confirmar'
		}).then((result) => {
				if(result){ 
					delete_(data_obj);
				}else if(result.dismiss === Swal.DismissReason.cancel){
					Swal.fire(
						'Cancelado',
						'Operação cancelada!',
						'error'
					)
				}
		}) 
	};

  return( 
		<div className="container-xxl bg-white p-0">
			<Navbar/>
			<div className='row'>
				<div className='col-8 offset-2 mt-5'>
					<h1 style={{'textAlign' : 'center'}}>
						Candidaturas
					</h1>					
					<Table table_name="applications_by_opportunity" table_columns={applications_table_columns}/>
				</div>
			</div>
			<Footer/>
			<ModalEdit table_name="applications" modal_title="Candidaturas" schema={schema_application} row_data={rowObj}/>
			<LogoutBtn />
		</div>
  );
};