import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import $ from 'jquery';
import { useEffect, useState } from 'react';


const JobOfferForm = (props) => {

  const [stateObj, setStateObj] = useState({})
  
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(props.schema)
  });

  useEffect( () => {
    if( typeof(props.row_info) != 'undefined' ){
      setValue('title', props.row_info[0].title)
      setValue('contact', props.row_info[0].contact)
      setValue('description', props.row_info[0].description)
      setValue('hard_skills', props.row_info[0].hard_skills)
      setValue('soft_skills', props.row_info[0].soft_skills)
      setValue('id_jobtype', props.row_info[0].id_jobtype)
      setValue('id_state', props.row_info[0].id_state)
      setValue('id_work', props.row_info[0].id_work)
    }
  }, [props?.row_info, setValue])

  return(     
    <form className="create_form" onSubmit={ handleSubmit(props.submit_function) }>
      <div className="modal-body">
        <div className="form-group row mt-3">
          <div className="col-4">
            <label className="col-12 form-label">Nome</label>
            <input type="text" className="form-control" {... register("title")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.title?.message}</label>
          </div>
          <div className="col-4">
            <label className="col-12 form-label">Contacto</label>
            <input type="text" id="contact" className="form-control" {... register("contact")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.title?.message}</label>
          </div>
          <div className="col-4">
            <label className="col-12 form-label">Descrição</label>
            <input type="text" className="form-control" {... register("description")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.title?.message}</label>
          </div>
        </div>

        <div className="form-group row mt-3">
          <div className="col-4">
            <label className="col-12 form-label">Hard Skills</label>
            <input type="text" className="form-control" {... register("hard_skills")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.title?.message}</label>
          </div>
          <div className="col-4">
            <label className="col-12 form-label">Soft Skills</label>
            <input type="text" id="contact" className="form-control" {... register("soft_skills")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.title?.message}</label>
          </div>
          <div className="col-4">
            <label className="col-12 form-label">Tipo de Vaga</label>
            <input type="text" className="form-control" {... register("id_jobtype")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.title?.message}</label>
          </div>
          <div className="col-4">
            <label className="col-12 form-label">Estao da Vaga</label>
            <input type="text" className="form-control" {... register("id_state")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.title?.message}</label>
          </div>
          <div className="col-4">
            <label className="col-12 form-label">Regime de Trabalho</label>
            <input type="text" className="form-control" {... register("id_work")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.title?.message}</label>
          </div>
        </div>

      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-primary">Submeter</button>
      </div>
    </form>
  )
}

export default JobOfferForm;