import $ from 'jquery';
import axios from 'axios';
import { useState, useEffect, useContext } from "react";

export const TotalUsers = () => {

  const [totalUsers, setTotalUsers] = useState(0)
  const [totalOpportunities, setTotalOpportunities] = useState(0)
  const [totalJobOffers, setTotalJobOffers] = useState(0)
  const [totalBusinessAreas, setTotalBusinessAreas] = useState(0)
  const [totalJobTypes, setTotalJobTypes] = useState(0)
  const [totalContacts, setTotalContacts] = useState(0)
  const [totalApplications, setTotalApplications] = useState(0)
  const [totalCenters, setTotalCenters] = useState(0)

  useEffect(() => {
    total_users()
    total_applications()
    total_job_offers()
    total_opportunities()
    total_business_areas()
    total_job_types()
    total_contacts()
    total_centers()
  }, [])


  const total_users = () => {
    axios.get("https://pint-22-23-backend.onrender.com/api/dashboard/total_users")
          .then((res) => {
            if(res.data.success){
              setTotalUsers(res.data.data);
            }
          })
          .catch((error) => {
            console.log(error)
          })
  }

  const total_applications = () => {
    axios.get("https://pint-22-23-backend.onrender.com/api/dashboard/total_applications")
          .then((res) => {
            if(res.data.success){
              setTotalApplications(res.data.data);
            }
          })
          .catch((error) => {
            console.log(error)
          })
  }

  const total_job_offers = () => {
    axios.get("https://pint-22-23-backend.onrender.com/api/dashboard/total_job_offers")
          .then((res) => {
            if(res.data.success){
              setTotalJobOffers(res.data.data);
            }
          })
          .catch((error) => {
            console.log(error)
          })
  }

  const total_job_types = () => {
    axios.get("https://pint-22-23-backend.onrender.com/api/job_offer_types")
          .then((res) => {
            if(res.data.success){
              setTotalJobTypes(res.data.data.length);
            }
          })
          .catch((error) => {
            console.log(error)
          })
  }

  const total_opportunities = () => {
    axios.get("https://pint-22-23-backend.onrender.com/api/dashboard/total_opportunities")
          .then((res) => {
            if(res.data.success){
              setTotalOpportunities(res.data.data);
            }
          })
          .catch((error) => {
            console.log(error)
          })
  }

  const total_business_areas = () => {
    axios.get("https://pint-22-23-backend.onrender.com/api/business_areas")
          .then((res) => {
            if(res.data.success){
              setTotalBusinessAreas(res.data.data.length);
            }
          })
          .catch((error) => {
            console.log(error)
          })
  }

  const total_contacts = () => {
    axios.get("https://pint-22-23-backend.onrender.com/api/contacts")
          .then((res) => {
            if(res.data.success){
              setTotalContacts(res.data.data.length);
            }
          })
          .catch((error) => {
            console.log(error)
          })
  }

  const total_centers = () => {
    axios.get("https://pint-22-23-backend.onrender.com/api/centers")
          .then((res) => {
            if(res.data.success){
              console.log(res.data.data)
              setTotalCenters(res.data.data.length);
            }
          })
          .catch((error) => {
            console.log(error)
          })
  }

  return(
    <div className="col-12 mt-5 card-group" style={{'justify-content':'space-around'}}>
      <div className="col-2 offset-0" style={{'border':'4px solid #36659d', 'borderRadius':'7px','color':'#36659d'}}>
        <div className="card-body">
          <h5 className="card-title">Utilizadores</h5>
          <h2>{totalUsers} </h2>
          <hr/>
          <h5 className="card-title">Oportunidades</h5>
          <h2>{totalOpportunities} </h2>
        </div>
      </div>
      <div className="col-2 offset-0" style={{'border':'4px solid #36659d', 'borderRadius':'7px','color':'#36659d'}}>
        <div className="card-body">
          <h5 className="card-title">Vagas</h5>
          <h2>{totalJobOffers} </h2>
          <hr/>
          <h5 className="card-title">Tipos de Vagas</h5>
          <h2>{totalJobTypes} </h2>
        </div>
      </div>
      <div className="col-2 offset-0" style={{'border':'4px solid #36659d', 'borderRadius':'7px','color':'#36659d'}}>
        <div className="card-body">
          <h5 className="card-title">Áreas de Negócio</h5>
          <h2>{totalBusinessAreas} </h2>
          <hr/>
          <h5 className="card-title">Contactos</h5>
          <h2>{totalContacts} </h2>
        </div>
      </div>
      <div className="col-2 offset-0" style={{'border':'4px solid #36659d', 'borderRadius':'7px','color':'#36659d'}}>
        <div className="card-body">
          <h5 className="card-title">Candidaturas</h5>
          <h2>{totalApplications} </h2>
          <hr/>
          <h5 className="card-title">Centros</h5>
          <h2>{totalCenters}</h2>
        </div>
      </div>
      {/* <div className="card" style={{'border':'4px solid #36659d', 'borderRadius':'7px','color':'#36659d'}}>
        <div className="card-body">
          <h5 className="card-title">Candidaturas</h5>
          <ul className="list-group list-group-flush" style={{'textAlign':'left'}}>
            <li style={{'textAlign':'left'}} className="list-group-item">&bull;Utilizadores - {totalUsers}</li>
            <li style={{'textAlign':'left'}} className="list-group-item">&bull;Colaborador - </li>
            <li style={{'textAlign':'left'}} className="list-group-item">&bull;Administrador - </li>
            <li style={{'textAlign':'left'}} className="list-group-item">&bull;Gestores - </li>
          </ul>
        </div>
      </div>
      <div className="card" style={{'border':'4px solid #36659d', 'borderRadius':'7px','color':'#36659d'}}>
        <div className="card-body">
          <h5 className="card-title">Oportunidades</h5>
          <ul className="list-group list-group-flush" style={{'textAlign':'left'}}>
            <li style={{'textAlign':'left'}} className="list-group-item">&bull;Utilizadores - {totalUsers}</li>
            <li style={{'textAlign':'left'}} className="list-group-item">&bull;Colaborador - </li>
            <li style={{'textAlign':'left'}} className="list-group-item">&bull;Administrador - </li>
            <li style={{'textAlign':'left'}} className="list-group-item">&bull;Gestores - </li>
          </ul>
        </div>
      </div> */}
    </div>
  )

}