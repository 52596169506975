import { useEdit } from '../hooks/useEdit';

import UserForm from '../components/forms/user_form';
import BusinessAreaForm from '../components/forms/business_area_form';
import ProjectTypeForm from '../components/forms/project_type_form';
import StateForm from '../components/forms/state_form';
import RoleForm from '../components/forms/role_form';
import JobOfferForm from '../components/forms/job_offer_form';
import ApplicationForm from '../components/forms/application_form';
import MeetingForm from './forms/meeting_form';


export const ModalEdit = (props) => {

	const { mutate: edit_ } = useEdit();

  const onEdit = (data) => {
		const data_obj = {table_name: props.table_name, data: data, id: props.id};
		edit_(data_obj);
  };

  return (
    <div className="modal fade" id="edit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" style={{'color':'#36659d'}}>Editar/Visualizar {props.modal_title}</h2>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          { props.table_name === 'users'           ? <UserForm          utility="edit" table_name={props.table_name} row_info={props.row_data} schema={props.schema} submit_function={onEdit}/> : '' }    
          { props.table_name === 'business_areas'  ? <BusinessAreaForm  utility="edit" table_name={props.table_name} row_info={props.row_data} schema={props.schema} submit_function={onEdit}/> : '' }    
          { props.table_name === 'project_types'   ? <ProjectTypeForm   utility="edit" table_name={props.table_name} row_info={props.row_data} schema={props.schema} submit_function={onEdit}/> : '' }    
          { props.table_name === 'states'          ? <StateForm         utility="edit" table_name={props.table_name} row_info={props.row_data} schema={props.schema} submit_function={onEdit}/> : '' }    
          { props.table_name === 'roles'           ? <RoleForm          utility="edit" table_name={props.table_name} row_info={props.row_data} schema={props.schema} submit_function={onEdit}/> : '' }    
          { props.table_name === 'job_offers'      ? <JobOfferForm      utility="edit" table_name={props.table_name} row_info={props.row_data} schema={props.schema} submit_function={onEdit}/> : '' }
          { props.table_name === 'applications'    ? <ApplicationForm   utility="edit" table_name={props.table_name} row_info={props.row_data} schema={props.schema} submit_function={onEdit}/> : '' }      
          { props.table_name === 'meetings'        ? <MeetingForm       utility="edit" 
                                                                        schema={props.schema} 
                                                                        submit_function={onEdit} 
                                                                        row_info={props.row_data} 
                                                                        table_name={props.table_name} 
                                                                        current_application_={props.current_application_}/> : '' } 
        </div>
      </div>
    </div>)
};