import { useState } from 'react';

import { BsFillPlusCircleFill } from 'react-icons/bs';
import { FaPen, FaTrash } from 'react-icons/fa';
import { BiChat } from 'react-icons/bi';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import * as yup from 'yup';

import { Navbar } from '../components/navbar';
import { Footer } from '../components/footer';
import { ModalCreate } from '../components/modal_create';
import { ModalEdit } from '../components/modal_edit';
import Table from '../components/table';
import { LogoutBtn } from '../components/logout_btn';

import { useDelete } from '../hooks/useDelete';
import { useGetDataById } from '../hooks/useGetDataById';


export const Applications = () => {

	const [deletionError, setDeletionError] = useState(null);

	const [rowObj, setRowObj] = useState({})
	const [rowObjMeeting, setRowObjMeeting] = useState({})

	const { mutate: delete_ } = useDelete(setDeletionError);
	const { mutate: getDataById_ } = useGetDataById();
	
  const applications_table_columns = [
		{
			Header: "Id",           
			accessor: "id_application"     
		},
		{
			Header: "Vaga", 
			accessor: "job_offer.title"
		},
		{
			Header: "Candidato", 
			accessor: "name",
      /* Cell: ({row}) => { 
        return (
          <div>
            <span className="candidate">{row.values.user?.first_name} {row.values.user?.last_name}</span>
          </div>
        )
      } */
		},
		{
			Header: "Email", 
			accessor: "email"
		},
		{
			Header: "Contacto", 
			accessor: "contact"
		},
		{
			Header: "CV", 
			accessor: "cv"
		},
		{
			Header: "Opções",
			Cell: ({row}) => (
				<>
					<BiChat 
						data-bs-toggle="modal" 
						data-bs-target="#create" 
						onClick={ () => { setRowObjMeeting(row.values); console.log(row.values.id_application); }}
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginRight": "10px", "cursor": "pointer", "color": "#36659d" }} 
					/>
					<FaPen 
						data-bs-toggle="modal" 
						data-bs-target="#edit" 
						onClick={ () => { console.log(row.values) }}
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginRight": "10px", "cursor": "pointer", "color": "#36659d" }} 
					/>
					<FaTrash 
						className="lixo_" 
						onClick={ () => deleteData('states', row.values.id_application) } 
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginLeft": "10px", "cursor": "pointer", "color": "#36659d" }} 
					/>
				</>
			),  
		}
	];

	const schema_meeting = yup.object().shape({
    meeting_date  : yup.date().required("Preencher!"),
    start_time    : yup.string().required("Preencher!"),
    description   : yup.string().required("Preencher!")
  });

	const schema_application = yup.object().shape({
    name    : yup.string().required("Preencher!"),
    email   : yup.string().email().required("Preencher!"),
    contact : yup.number().required("Preencher!"),
    cv      : yup.string().required("Preencher!")
  });

	function getDataByID(table_name, id){
		const data_obj = {table_name: table_name, id: id, modal_title: "Candidaturas"};
		getDataById_(data_obj);
	}

	function deleteData(table_name, id){

		const data_obj = {table_name: table_name, id: id};

		Swal.fire({
			title: 'Tem a certeza?',
			text: 'Não poderá recuperar este ficheiro!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Confirmar'
		}).then((result) => {
				if(result){
					delete_(data_obj);
				}else if(result.dismiss === Swal.DismissReason.cancel){
					Swal.fire(
						'Cancelado',
						'Operação cancelada!',
						'error'
					)
				}
		}) 
	};

  return( 
		<div className="container-xxl bg-white p-0">
			<Navbar/>
			<div className='row'>
				<div className='col-8 offset-2 mt-5'>
					<h1 style={{'textAlign' : 'center'}}>
						Candidaturas
					</h1>					
					<Table table_name="applications_by_job_offer" table_columns={applications_table_columns}/>
				</div>
			</div>
			<Footer/>
			<ModalCreate table_name="meetings" modal_title="Entrevista" schema={schema_meeting} current_application_={rowObjMeeting.id_application}/>
			<ModalEdit table_name="applications" modal_title="Candidaturas" schema={schema_application} row_data={rowObj}/>
			<LogoutBtn />
		</div>
  );
};