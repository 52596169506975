import { useState } from 'react';

import { BsFillPlusCircleFill } from 'react-icons/bs';
import { BiChat } from 'react-icons/bi';
import { FaPen, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import * as yup from 'yup';

import { Navbar } from '../components/navbar';
import { Footer } from '../components/footer';
import { ModalCreate } from '../components/modal_create';
import { ModalEdit } from '../components/modal_edit';
import Table from '../components/table';

import { useDelete } from '../hooks/useDelete';
import { useGetDataById } from '../hooks/useGetDataById';


export const Contacts = () => {

	const [deletionError, setDeletionError] = useState(null);
	const [rowObjContact, setRowObjContact] = useState({})

	const { mutate: delete_ } = useDelete(setDeletionError);
	const { mutate: getDataById_ } = useGetDataById();

  const contacts_table_columns = [
		{
			Header: "Id",           
			accessor: "id_contact"     
		},
		{
			Header: "Oportunidade", 
      accessor: "opportunity.name"
		},
		{
			Header: "Utilizador", 
			accessor: "user",
      Cell: ({row}) => { 
        return (
          <div>
            <span className="user_">{row.values.user?.first_name} {row.values.user?.last_name}</span>
          </div>
        )
      }
		},
		{
			Header: "Dia", 
			accessor: "contact_date"
		},
		{
			Header: "Contacto", 
			accessor: "phone_number"
		},
		{
			Header: "Opções",
			Cell: ({row}) => (
				<>
					<BiChat 
						data-bs-toggle="modal" 
						data-bs-target="#create" 
						onClick={ () => { setRowObjContact(row.values); console.log(row.values.id_application); }}
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginRight": "10px", "cursor": "pointer", "color": "#36659d" }} 
					/>
					<FaPen 
						data-bs-toggle="modal" 
						data-bs-target="#edit" 
						onClick={ () => getDataByID('users', row.values.id_contact) }
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginRight": "10px", "cursor": "pointer", "color": "#36659d" }} 
					/>
					<FaTrash className="lixo_" onClick={ () => deleteData('meetings', row.values.id_contact) } value={{ "height": "1em", "width": "1em" }} style={{ "marginLeft": "10px", "cursor": "pointer", "color": "#36659d" }} />
				</>
			),  
		}
	];

  const schema = yup.object().shape({
    email  				: yup.string("Preencher email!").email("Preencher email!").required("Preencher email!"),
    phone_number  : yup.string("Preencher!").required("Preencher!"),
    contact_date  : yup.date("Preencher!").typeError("Preencher!")
  });

	function getDataByID(table_name, id){
		const data_obj = {table_name: table_name, id: id, modal_title: "Contactos"};
		getDataById_(data_obj);
	}

	function deleteData(table_name, id){

		const data_obj = {table_name: table_name, id: id};

		Swal.fire({
			title: 'Tem a certeza?',
			text: 'Não poderá recuperar este ficheiro!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Confirmar'
		}).then((result) => {
				if(result){
					delete_(data_obj);
				}else if(result.dismiss === Swal.DismissReason.cancel){
					Swal.fire(
						'Cancelado',
						'Operação cancelada!',
						'error'
					)
				}
		}) 
	};

  return( 
		<div className="container-xxl bg-white p-0">
			<Navbar/>
			<div className='row'>
				<div className='col-8 offset-2 mt-5'>
					<h1 style={{'textAlign' : 'center'}}>
						Contactos
					</h1>					
					<Table table_name="contacts" table_columns={contacts_table_columns}/>
				</div>
			</div>
			<Footer/>
			<ModalCreate table_name="contacts" modal_title="Contactos" schema={schema}/>
			<ModalEdit table_name="contacts" modal_title="Contactos" schema={schema}/>
		</div>
  );

}