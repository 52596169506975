import * as React from 'react';
import { useState, useEffect } from "react";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import $ from 'jquery';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

import YupPassword from 'yup-password';
import '../template/css/login.css';

YupPassword(yup);


export const RecoverPassword = () => {

	let navigate = useNavigate()

  const schema = yup.object().shape({
    password                : yup.string().password()
                                .min(8, "Tem que ter no mínimo 8 caracteres")
                                .minUppercase(1, "Tem que ter no mínimo 1 caracter maiúsculo")
                                .minSymbols(1, "Tem que ter no mínimo 1 caracter especial")
                                .minNumbers(1, 'Tem que ter no mínimo 1 número')
                                .required("Campo obrigatório!"),
    password_confirmation   : yup.string().oneOf([yup.ref('password'), null], "Palavras-Passe não são iguais!")
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });  

	const recoverPass = () => {
		let email_ = window.location.href.split('/')[window.location.href.split('/').length - 1];

		let pass = { password: $('#password_confirmation').val() };
		
		Swal.showLoading();
		
		axios.post("https://pint-22-23-backend.onrender.com/api/change_password/" + email_, pass)
			.then(() => {
				Swal.close();
				return Swal.fire({
					title: 'Password Atualizada com sucesso!',
					icon: 'success',
					allowOutsideClick: false,
					allowEscapeKey: false
				}).then((result) => {
					if (result.isConfirmed) {
						navigate('/');
					}
				}) 
			})
			.catch(() => {
				return Swal.fire({
					title: 'Erro!',
					text: 'Erro ao atualizar password! Por favor tente novamente.',
					icon: 'error'
				})})		
	}
  	
	useEffect(() => {
		setTimeout(() => {
			document.getElementById('container').classList.add('login_sign-in')
			document.querySelector('html').style.height = '100vh';
		}, 200)
	}, []); 

  return( 
		<div id="container" className="login_container">

			<div className="login_row">
        
				<div id="register" className="login_col login_align-items-center login_flex-col login_sign-up">				
				</div>

				<div id="login" className="login_col login_align-items-center login_flex-col login_sign-in">
					<div className="login_form-wrapper login_align-items-center">
						<div className="login_form login_sign-in">
							<div className="login_input-group">
                <label>Palavra-Passe</label>
								<input id="password" type="password" placeholder="Palavra-Passe" {...register("password")}/>
                <small 	className="requirement min_car" 
												style={{ 'color' : errors?.password?.message.includes('8') && $('#password').val().length < 8 ? 'red' : '' }}>
									&bull;Mínimo de 8 caracteres
								</small><br/>
                <small 	className="requirement car_m" 
												style={{ 'color' : errors?.password?.message.includes('maiúsculo') ? 'red' : '' }}>
									&bull;Caracter maiúsculo
								</small><br/>
                <small 	className="requirement car_n" 
												style={{ 'color' : errors?.password?.message.includes('número') ? 'red' : '' }}>
									&bull;Caracter numérico
								</small><br/>
                <small 	className="requirement car_e" 
												style={{ 'color' : errors?.password?.message.includes('especial') ? 'red' : '' }}>
									&bull;Caracter especial
								</small>
							</div>
							<div className="login_input-group">
                <label>Confirmar Palavra-Passe</label>
								<input id="password_confirmation" type="password" placeholder="Confirmar Palavra-Passe" {...register("password_confirmation")}/>
                <label className="form-label" style={ {'color': 'red'}}>{errors?.password_confirmation?.message}</label>
							</div>
							<button onClick={handleSubmit(recoverPass)}>Recuperar palavra-passe</button>
						</div>
					</div>
				</div>
			</div>

			<div className="login_row login_content-row">
			<div className="login_col login_align-items-center login_flex-col">
					<div className="login_img login_sign-in">	
						{/* <img 	alt="" width={'450px'} style={{'background': 'white', 'borderRadius': '10px'}}
									src="https://www.neptune-software.com/wp-content/uploads/2017/11/logotipo_softinsa_2016.png" /> */}	
					</div>
					<div className="login_text login_sign-in">
						<h2 style={{'fontSize':'45px'}}>Business Solutions</h2>		
					</div>
				</div>
			</div>
		</div>
  );
};