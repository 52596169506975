import { useCreate } from '../hooks/useCreate';

import UserForm from '../components/forms/user_form';
import BusinessAreaForm from '../components/forms/business_area_form';
import ProjectTypeForm from '../components/forms/project_type_form';
import StateForm from '../components/forms/state_form';
import RoleForm from '../components/forms/role_form';
import JobOfferForm from '../components/forms/job_offer_form';
import ApplicationForm from '../components/forms/application_form';
import MeetingForm from '../components/forms/meeting_form';
import ContactForm from '../components/forms/contact_form';


export const ModalCreate = (props) => {

  const { mutate: create_ } = useCreate();

  const onCreate = (data) => {
    console.log(data)
    console.log(props.table_name)
		const data_obj = {table_name: props.table_name, data: data};
		create_(data_obj);
  };

  const title = (table_name) => {
    if( table_name === 'applications'){
      return '';
    }else if( table_name === 'meetings'){
      return 'Marcar';
    } else {
      return 'Criar';
    }
  }

  return (
    <div className="modal fade" id="create" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" style={{'color':'#36659d'}}>
              {title(props.table_name)} { props.modal_title.indexOf('Oportunidade') !== -1 ? props.modal_title : '' }
              {/* { props.table_name !== 'applications' ? 'Criar'   : ''} {props.modal_title}
              { props.table_name === 'meetings'     ? 'Marcar'  : 'Criar'} {props.modal_title} */}
            </h2>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          { props.table_name === 'users'           ? <UserForm          utility="create" table_name={props.table_name} schema={props.schema} submit_function={onCreate}/> : '' }    
          { props.table_name === 'business_areas'  ? <BusinessAreaForm  utility="create" table_name={props.table_name} schema={props.schema} submit_function={onCreate}/> : '' }    
          { props.table_name === 'project_types'   ? <ProjectTypeForm   utility="create" table_name={props.table_name} schema={props.schema} submit_function={onCreate}/> : '' }    
          { props.table_name === 'states'          ? <StateForm         utility="create" table_name={props.table_name} schema={props.schema} submit_function={onCreate}/> : '' }    
          { props.table_name === 'roles'           ? <RoleForm          utility="create" table_name={props.table_name} schema={props.schema} submit_function={onCreate}/> : '' }    
          { props.table_name === 'job_offers'      ? <JobOfferForm      utility="create" table_name={props.table_name} schema={props.schema} submit_function={onCreate}/> : '' }      
          { props.table_name === 'meetings'        ? <MeetingForm       utility="create" 
                                                                        schema={props.schema} 
                                                                        submit_function={onCreate} 
                                                                        table_name={props.table_name} 
                                                                        current_application_={props.current_application_}/> : '' }    
          { props.table_name === 'applications'    ? <ApplicationForm   utility="create" 
                                                                        schema={props.schema} 
                                                                        submit_function={onCreate} 
                                                                        table_name={props.table_name} 
                                                                        current_user_={props.current_user_} 
                                                                        current_job_offer_={props.current_job_offer_} 
                                                                        current_opportunity_={props.current_opportunity_}/> : '' } 
          { props.table_name === 'contacts'    ? <ContactForm           utility="create" 
                                                                        schema={props.schema} 
                                                                        submit_function={onCreate} 
                                                                        table_name={props.table_name} 
                                                                        current_opportunity_={props.current_opportunity_}/> : '' }   
        </div>
      </div>
    </div>)
};