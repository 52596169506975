import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useState, createContext } from "react";
import { Provider } from 'react-redux';
import $ from 'jquery';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './App.css';
import './template/lib/animate/animate.min.css';
import './template/lib/owlcarousel/assets/owl.carousel.min.css';
import './template/css/style.css';

import {ProtectedRoutes} from "./protectedRoutes";

/* import './template/js/main';
import './template/lib/wow/wow';
import './template/lib/waypoints/waypoints.min.js';
import './template/lib/owlcarousel/owl.carousel'; */

import { store } from './auth';

//Pages
import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Users } from './pages/Users';
import { States } from './pages/States';
import { BusinessAreas } from './pages/BusinessAreas';
import { ProjectTypes } from './pages/ProjectTypes';
import { Roles } from './pages/Roles';
import { RecoverPassword } from './pages/RecoverPassword';
import { JobOffers } from './pages/JobOffers';
import { Applications } from './pages/Applications';
import { MyApplications } from './pages/MyApplications';
import { Opportunities } from './pages/Opportunities';
import { Meetings } from './pages/Meetings';
import { ActivateAccount } from './pages/ActivateAccount';
import { Contacts } from './pages/Contacts';
import { ApplicationsFromOpportunities } from './pages/ApplicationsFromOpportunities';

//Components
import { Navbar } from './components/navbar';
import { Carousel } from './components/carousel';
import { Dashboard } from './components/dashboard';

$('body').css('backgroundColor', '#EFFDF5');

export const UserContext = createContext();

function App() {
  const client = new QueryClient();
  const [logIn, setLogIn] = useState(false);

  return (
    <div className="App">
      <Provider store={ store }>
        <QueryClientProvider client={ client }>
          <Router>
            <Routes>
              <Route path="/login" element={ <Login isLoggedIn={document.cookie.indexOf('true') !== -1} changeIsLoggedIn={setLogIn}/> } />
              <Route path="*" element={ <h1>Page not found</h1> } />
              <Route path="/recuperarpassword/*" element={ <RecoverPassword/> } />
              <Route path="/ativar_conta" element={ <ActivateAccount/> } />
              <Route element={<ProtectedRoutes isAllowed={document.cookie.indexOf('true') !== -1} />}>
                <Route path="/" element={ <Home isLoggedIn={[logIn, document.cookie.indexOf('true') !== -1]} changeIsLoggedIn={setLogIn}/> } />
                <Route path="/info" element={ <About/> } />
                <Route path="/utilizadores" element={ <Users/> } />       
                <Route path="/estados" element={ <States/> } />       
                <Route path="/areasnegocio" element={ <BusinessAreas/> } />       
                <Route path="/tiposprojeto" element={ <ProjectTypes/> } />       
                <Route path="/cargos" element={ <Roles/> } />       
                <Route path="/vagas" element={ <JobOffers/> } />       
                <Route path="/oportunidades" element={ <Opportunities/> } />       
                <Route path="/candidaturas" element={ <Applications/> } />       
                <Route path="/candidaturas_oportunidades" element={ <ApplicationsFromOpportunities/> } />       
                <Route path="/entrevistas" element={ <Meetings/> } />       
                <Route path="/contactos" element={ <Contacts/> } />       
                <Route path="/minhas_candidaturas" element={ <MyApplications/> } />       
              </Route>
            </Routes>
          </Router>     
        </QueryClientProvider>
      </Provider>
    </div>
  );
}

export default App;