import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import $ from 'jquery';
import { useEffect, useState } from 'react';


const StateForm = (props) => {

  const [stateObj, setStateObj] = useState({})
  const [name_, setName_] = useState()
  const [email_, setEmail_] = useState()
  
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(props.schema)
  });

  const checkOut = (e) => {
    setName_(e.target.value)
    console.log(12312)
  }

  useEffect( () => {
    setStateObj(props.row_info)
    setValue('name', props?.row_info?.name)
  }, [props?.row_info])

  return(     
    <form className="create_form" onSubmit={ handleSubmit(props.submit_function) }>
      <div className="modal-body">
        <div className="form-group row mt-3">
          <div className="col-4">
            <label className="col-12 form-label">Nome</label>
            <input type="text" id="name" className="form-control" value={name_} onChange={(e) => checkOut(e)} {... register("name")}/>
            <label className="form-label" style={ {'color': 'red'}}>{errors?.first_name?.message}</label>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="submit" className="btn btn-primary">Submeter</button>
      </div>
    </form>
  )
}

export default StateForm;