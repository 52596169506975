import { useState, useEffect } from 'react'
import axios from 'axios';


export const OpportunitiesByState = () => {

  const [opportunitiesByState, setOpportunitiesByState] = useState([])

  useEffect(() => {
    const opportunitiesByStateFn = async () => {
      await axios.get("https://pint-22-23-backend.onrender.com/api/opportunities")
      .then((res) => {
        setOpportunitiesByState(res.data.data)
      })
      .catch((error) => {
        console.log(error)
      })  
    }
    opportunitiesByStateFn()
  }, [])


  return(
    <div className="card col-3" style={{'border':'4px solid #36659d', 'borderRadius':'7px','color':'#36659d'}}>
      <div className="card-body mb-3" style={{'height':'400px'}}>
        <h5 className="card-title">Oportunidades por Estados</h5>

        <ul className="list-group list-group-flush" style={{'textAlign':'left'}}>
          <li style={{'textAlign':'left', 'color':'#36659d', 'fontSize':'20px'}} className="list-group-item">Ativas</li>
            <ul className="list-group list-group-flush" style={{'textAlign':'left'}}>
            {opportunitiesByState?.filter(x=> x.id_state == '7')?.map((opportunity) => {
              return (<li style={{'textAlign':'left'}} className="list-group-item">&bull;{opportunity.name}</li>  )               
            })}
            </ul>
        </ul>
        <hr/>
        <ul className="list-group list-group-flush" style={{'textAlign':'left'}}>
          <li style={{'textAlign':'left', 'color':'#36659d', 'fontSize':'20px'}} className="list-group-item">Terminadas</li>
            <ul className="list-group list-group-flush" style={{'textAlign':'left'}}>
            {opportunitiesByState?.filter(x=> x.id_state == '12')?.map((opportunity) => {
              return (<li style={{'textAlign':'left'}} className="list-group-item">&bull;{opportunity.name}</li>  )               
            })}              
            </ul>
        </ul>

      </div>
    </div>
  )

}