import { useState } from 'react';

import { BsFillPlusCircleFill } from 'react-icons/bs';
import { FaPen, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import * as yup from 'yup';

import { Navbar } from '../components/navbar';
import { Footer } from '../components/footer';
import { ModalCreate } from '../components/modal_create';
import { ModalEdit } from '../components/modal_edit';
import Table from '../components/table';

import { useDelete } from '../hooks/useDelete';
import { useGetDataById } from '../hooks/useGetDataById';
import { returnDataByID } from '../hooks/getDataById';


export const Meetings = () => {

	const [deletionError, setDeletionError] = useState(null);
	const [rowObj, setRowObj] = useState({})

	const { mutate: delete_ } = useDelete(setDeletionError);
	const { mutate: getDataById_ } = useGetDataById();

  const meetings_table_columns = [
		{
			Header: "Id",           
			accessor: "id_meeting"     
		},
		{
			Header: "Candidato", 
      accessor: "candidate"
		},
		{
			Header: "Email do candidato", 
      accessor: "candidate_email"
		},
		{
			Header: "Vaga", 
			accessor: "job_offer_title"
		},
		{
			Header: "Dia", 
			accessor: "meeting_date"
		},
		{
			Header: "Hora", 
			accessor: "start_time"
		},
		{
			Header: "CV", 
			accessor: "cv"
		},
		{
			Header: "Link Entrevista", 
			accessor: "meeting_link"
		},
		{
			Header: "Estado", 
			accessor: "state_name"    
		},
		{
			Header: "Opções",
			Cell: ({row}) => (
				<>
					<FaPen 
						data-bs-toggle="modal" 
						data-bs-target="#edit" 
						onClick={ () => getDataByID('meetings', row.values.id_meeting) }
						value={{ "height": "1em", "width": "1em" }} 
						style={{ "marginRight": "10px", "cursor": "pointer", "color": "#36659d" }} 
					/>
					<FaTrash className="lixo_" onClick={ () => deleteData('meetings', row.values.id_meeting) } value={{ "height": "1em", "width": "1em" }} style={{ "marginLeft": "10px", "cursor": "pointer", "color": "#36659d" }} />
				</>
			),  
		}
	];

  const schema_create = yup.object().shape({
    name  				: yup.string().required("Preencher!"),
    description   : yup.string().required("Preencher!"),
    start_time  	: yup.string(),
    meeting_date  : yup.string(),
    meeting_link  : yup.string()
  });

  const schema_edit = yup.object().shape({
    description   : yup.string().required("Preencher!"),
    score   			: yup.number("Preencher classificação!")
												.positive("Preencher classificação!")
												.integer("Preencher classificação!")
												.typeError("Preencher classificação")
												.required("Preencher classificação!")
  });

	async function getDataByID(table_name, id){
		const data_obj = {table_name: table_name, id: id, modal_title: "Entrevistas"};
		const result = await returnDataByID(data_obj);		
		setRowObj(result)
	}

	function deleteData(table_name, id){

		const data_obj = {table_name: table_name, id: id};

		Swal.fire({
			title: 'Tem a certeza?',
			text: 'Não poderá recuperar este ficheiro!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Confirmar'
		}).then((result) => {
				if(result){
					delete_(data_obj);
				}else if(result.dismiss === Swal.DismissReason.cancel){
					Swal.fire(
						'Cancelado',
						'Operação cancelada!',
						'error'
					)
				}
		}) 
	};

  return( 
		<div className="container-xxl bg-white p-0">
			<Navbar/>
			<div className='row'>
				<div className='col-8 offset-2 mt-5'>
					<h1 style={{'textAlign' : 'center'}}>
						Entrevistas
					</h1>					
					<Table table_name="meetings" table_columns={meetings_table_columns}/>
				</div>
			</div>
			<Footer/>
			<ModalCreate table_name="meetings" modal_title="Entrevistas" schema={schema_create}/>
			<ModalEdit table_name="meetings" modal_title="Entrevistas" schema={schema_edit} row_data={rowObj} id={rowObj[0]?.id_meeting}/>
		</div>
  );

}