import { useEffect, useState, useMemo } from "react";
import { useGetData } from '../hooks/useGetData';
import axios from 'axios';
import $ from 'jquery';

export const WorksLi = () => {

  const [allWorks, setAllWorks] = useState([]);

  useEffect(() => {    
    fetchData_works();
  }, [])

  //https://pint--22-23---backend.fly.dev/api/
  
  async function fetchData_works(){
    await axios.get("https://pint-22-23-backend.onrender.com/api/works")
                .then((res) => {
                  console.log(res.data.data);
                  setAllWorks(res.data.data);
                })
  }
  
  setTimeout(() => {
    $('.work_name').on('click', function(){
      let id_ = $(this).attr("data-tab");
      $('.tab-content .tab-pane').removeClass('active');
      $('#'+id_).addClass('active');
    })
  }, 2500)
  
  return(
    allWorks?.map(function(object_, i){
      return (
        <li className="nav-item" key={i}>
          <a className="work_name d-flex align-items-center text-start mx-3 ms-0 pb-3 active" data-tab={'tab-' + ++i} data-bs-toggle="pill" href="#" >
            <h6 className="mt-n1 mb-0">{object_.name}</h6>
          </a>
        </li>
      )
    })
  )

};